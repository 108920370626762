import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import Heading from '@frontend/ui-kit/Components/Heading';
import Icon from '@frontend/ui-kit/Components/Icon';
import Input from '@frontend/ui-kit/Components/Input';
import Label from '@frontend/ui-kit/Components/Label';
import Row from '@frontend/ui-kit/Components/Row';
import Select from '@frontend/ui-kit/Components/Select';
import Text from '@frontend/ui-kit/Components/Text';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Footer from './Footer';
import {Form, Field} from '../../shared/FormComponents';
import Section from './Section';
import ExternalSftp from '../ExternalSftp';
import {getFormattedDataMapping, validateMaxLength, validateRequired, validateHeaderlessEntries} from '../../../utils';
import {redirectTo} from '../../../actions/general';
import {initImportConfigForm, saveImportConfigForm, saveExternalSftp} from '../../../actions/importConfigs';
import {
    FORMS,
    FORM_GLOBAL_ERROR,
    ROUTES,
    IMPORT_METHOD_OPTIONS,
    ROWS_PER_RECORD_VALUES,
    TPA_DATA_PURPOSES,
    TPA_FILE_TYPES,
    HEADERLESS_TOOLTIP,
    MARKETPLACE_TOOLTIP
} from '../../../constants';
import './index.scss';
import Encryption from './Encryption';

const validate = values => ({
    tpa_partner_id: validateRequired(values.tpa_partner_id),
    import_method: validateRequired(values.import_method),
    event_id: validateHeaderlessEntries(values.is_headerless, values.event_id),
    verification_date: validateHeaderlessEntries(values.is_headerless, values.verification_date),
    medical_member_id: validateHeaderlessEntries(values.is_headerless, values.medical_member_id),
    system_employee_id: validateHeaderlessEntries(values.is_headerless, values.system_employee_id),
    ssn: validateHeaderlessEntries(values.is_headerless, values.ssn),
    patient_fname: validateHeaderlessEntries(values.is_headerless, values.patient_fname),
    patient_lname: validateHeaderlessEntries(values.is_headerless, values.patient_lname),
    patient_dob: validateHeaderlessEntries(values.is_headerless, values.patient_dob),
    relationship: validateHeaderlessEntries(values.is_headerless, values.relationship),
    employer_name: validateHeaderlessEntries(values.is_headerless, values.employer_name),
    group_number: validateHeaderlessEntries(values.is_headerless, values.group_number),
    procedure_category: validateHeaderlessEntries(values.is_headerless, values.procedure_category),
    contact_type: validateHeaderlessEntries(values.is_headerless, values.contact_type),
    provider_name: validateHeaderlessEntries(values.is_headerless, values.provider_name),
    provider_npi: validateHeaderlessEntries(values.is_headerless, values.provider_npi),
    facility_name: validateHeaderlessEntries(values.is_headerless, values.facility_name),
    npi: validateHeaderlessEntries(values.is_headerless, values.npi),
    internal_notes: validateMaxLength(300, values.internal_notes)
});

const InsuranceVerificationDataStorageForm = ({partnerOptions}) => {
    const dispatch = useDispatch();
    const {id: configId} = useParams();
    const [initialValues, setInitialValues] = useState({});
    const [importConfigId, setImportConfigId] = useState(configId);
    const [importMethod, setImportMethod] = useState();
    const [isEdit, setIsEdit] = useState(!!importConfigId);
    const [isHeaderless, setIsHeaderless] = useState(false);
    const [isMarketplaceIdentifiable, setIsMarketplaceIdentifiable] = useState(false);
    const [isEncrypted, setIsEncrypted] = useState();
    const [isExternalSftp, setIsExternalSftp] = useState();

    const onSubmit = useCallback(
        async values => {
            const {
                id: configId,
                tpa_partner: tpaPartner,
                tpa_file_type: tpaFileType,
                data_purpose: dataPurpose,
                partnerOptions: partners,
                data_mappings: dataMappings,
                tpa_partner_id: tpaPartnerId,
                import_method: importMethod,
                has_multiple_rows_per_record: rowsPerRecord,
                join_key: joinKey,
                internal_notes: internalNotes,
                is_headerless: isHeaderless,
                is_marketplace_identifiable: isMarketplaceIdentifiable,
                is_encrypted_file: isEncrypted,
                vault_path: vaultPath,
                is_external_sftp: isExternalSftp,
                external_sftp_id: externalSftpId,
                external_sftp: {
                    credentials_link: credentialsLink,
                    directory_path: directoryPath,
                    filename_pattern: filenamePattern,
                    sync_frequency_in_hours: syncFrequencyInHours,
                    last_sync: lastSync
                } = {},
                ...dataMappingsFormValues
            } = values;

            const formDataMappings = Object.entries(dataMappingsFormValues).map(([fieldName, fieldValue]) => ({
                healthjoy_field: fieldName,
                data_field: fieldValue
            }));
            const formattedDataMappings = formDataMappings.map(getFormattedDataMapping).flat();

            const filterOutEmpties = mapping => {
                const {data_field: fieldValue} = mapping;

                return !!fieldValue;
            };

            const validDataMappings = formattedDataMappings.filter(filterOutEmpties);
            const importConfigRequestParams = {
                tpa_file_type: TPA_FILE_TYPES.insuranceVerification,
                data_purpose: TPA_DATA_PURPOSES.dataStorage,
                tpa_partner_id: tpaPartnerId,
                import_method: importMethod,
                join_key: rowsPerRecord === ROWS_PER_RECORD_VALUES.multipleRowsPerRecord ? joinKey : null,
                data_mappings: validDataMappings,
                is_headerless: isHeaderless || false,
                is_marketplace_identifiable: isMarketplaceIdentifiable || false,
                is_encrypted_file: isEncrypted,
                vault_path: vaultPath || null,
                is_external_sftp: importMethod === 'Manual Upload' ? false : isExternalSftp,
                external_sftp_id: externalSftpId || null,
                internal_notes: internalNotes || null
            };

            if (isExternalSftp && importMethod === 'SFTP') {
                const todaysDate = new Date().toISOString();

                const externalSftpRequestParams = {
                    credentials_link: credentialsLink,
                    directory_path: directoryPath || '',
                    filename_pattern: filenamePattern,
                    sync_frequency_in_hours: syncFrequencyInHours,
                    last_sync: lastSync || todaysDate
                };

                const {data, isSuccess, submissionGlobalError} = await dispatch(
                    saveExternalSftp(externalSftpId, {...externalSftpRequestParams})
                );

                if (!isSuccess) {
                    return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
                }

                importConfigRequestParams.external_sftp_id = data.id;
            }

            const {data, isSuccess, isEdit, submissionGlobalError} = await dispatch(
                saveImportConfigForm(configId, {...importConfigRequestParams})
            );
            const {id: importConfigId} = data;

            if (!isSuccess) {
                return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
            }

            setInitialValues(values);
            setIsEdit(isEdit);
            setImportConfigId(importConfigId);
            setIsHeaderless(isHeaderless);
            setIsMarketplaceIdentifiable(isMarketplaceIdentifiable);
        },
        [dispatch]
    );

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(
                initImportConfigForm(importConfigId)
            );
            const {
                is_encrypted_file: isEncrypted,
                is_external_sftp: isExternalSftp,
                import_method: importMethod
            } = data;

            setInitialValues(data);
            setIsEncrypted(isEncrypted);
            setIsExternalSftp(isExternalSftp);
            setImportMethod(importMethod);
        })();
    }, [dispatch, importConfigId]);

    const onSubmitSuccess = useCallback(async () => {
        if (!isEdit) {
            dispatch(redirectTo(`${ROUTES.importConfigs}/${importConfigId}/edit?id=${importConfigId}`));
        }
    }, [dispatch, importConfigId, isEdit]);

    const onImportMethodChange = value => {
        setImportMethod(value);

        if (importMethod === 'Manual Upload') {
            setIsExternalSftp(false);
        }
    };

    const onHeaderlessChange = (event, form) => {
        event.persist();
        setIsHeaderless(isHeaderless => !isHeaderless);
        form.change('is_headerless', !isHeaderless);
    };

    const onMarketplaceIdentifiableChange = (event, form) => {
        event.persist();
        setIsMarketplaceIdentifiable(isMarketplaceIdentifiable => !isMarketplaceIdentifiable);
        form.change('is_marketplace_identifiable', !isMarketplaceIdentifiable);
    };

    const headerlessTooltipText = <Text className='information-paragraph'>{HEADERLESS_TOOLTIP.body}</Text>;

    const headerlessTooltip = (
        <div>
            Headerless
            <Tooltip isClickable content={headerlessTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    const marketplaceTooltipText = <Text className='information-paragraph'>{MARKETPLACE_TOOLTIP.body}</Text>;

    const marketplaceTooltip = (
        <div>
            HealthJoy-generated Identifier
            <Tooltip isClickable content={marketplaceTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    return (
        <div className='import-config-form'>
            <Column offset={0} sm={12}>
                <Form
                    name={FORMS.insuranceVerificationImportConfig}
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={onSubmit}
                    onSubmitSuccess={onSubmitSuccess}
                >
                    {({handleSubmit, submitError}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Section header='Data Source' offset={0} sm={12}>
                                    <Row className='import-config-form-upper-margin'>
                                        <Column sm={4}>
                                            <Label label='Source' type='select'>
                                                <Field name='tpa_partner_id'>
                                                    {props => <Select {...props} options={partnerOptions} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Import Method' type='select'>
                                                <Field name='import_method' onChange={onImportMethodChange}>
                                                    {props => <Select {...props} options={IMPORT_METHOD_OPTIONS} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Encryption isEncrypted={isEncrypted} />
                                        {importMethod === 'SFTP' && <ExternalSftp isExternalSftp={isExternalSftp} />}
                                    </Row>
                                </Section>
                                <Section header='Data Mapping' offset={0} sm={12} displaySeparator={false}>
                                    <Row className='import-config-form-upper-margin'>
                                        <Column sm={4}>
                                            <Label label={headerlessTooltip}>
                                                <Field name='is_headerless' onChange={onHeaderlessChange}>
                                                    {props => <Checkbox {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label={marketplaceTooltip}>
                                                <Field name='is_marketplace_identifiable' onChange={onMarketplaceIdentifiableChange}>
                                                    {props => <Checkbox {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                    </Row>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Event information
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Event ID' type='input'>
                                                    <Field name='event_id'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Date' type='input'>
                                                    <Field name='verification_date'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Member information
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Member ID' type='input'>
                                                    <Field name='medical_member_id'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='System Employee ID' type='input'>
                                                    <Field name='system_employee_id'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Social Security Number' type='input'>
                                                    <Field name='ssn'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Patient First Name' type='input'>
                                                    <Field name='patient_fname'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Patient Last Name' type='input'>
                                                    <Field name='patient_lname'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Patient Date of Birth' type='input'>
                                                    <Field name='patient_dob'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Relationship' type='input'>
                                                    <Field name='relationship'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Employer Name' type='input'>
                                                    <Field name='employer_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Group ID' type='input'>
                                                    <Field name='group_number'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Procedure & service
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Procedure Category' type='input'>
                                                    <Field name='procedure_category'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Contact Type' type='input'>
                                                    <Field name='contact_type'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Facility & provider
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Provider Name' type='input'>
                                                    <Field name='provider_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Provider NPI' type='input'>
                                                    <Field name='provider_npi'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Facility Name' type='input'>
                                                    <Field name='facility_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Facility NPI' type='input'>
                                                    <Field name='npi'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                </Section>
                                <Footer importConfigId={importConfigId} submitError={submitError} />
                            </form>
                        );
                    }}
                </Form>
            </Column>
        </div>
    );
};

InsuranceVerificationDataStorageForm.propTypes = {
    partnerOptions: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.number}))
};

export {InsuranceVerificationDataStorageForm as TestableInsuranceVerificationDataStorageForm};
export default React.memo(InsuranceVerificationDataStorageForm);
