import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import Button from '@frontend/ui-kit/Components/Button';
import FileUploader from '@frontend/ui-kit/ReduxFormComponents/FileUploader';
import Label from '@frontend/ui-kit/Components/Label';
import Input from '@frontend/ui-kit/ReduxFormComponents/Input';
import {saveFileUploadForm, uploadManualFile} from '../../../../actions/importConfigs';
import {ACCEPTABLE_TEXT_FORMATS, FORMS, MANUAL_FILE_MAX_FILE_COUNT} from '../../../../constants';
import {compose, validateMaxLength, validateRequired, getFileNameFromPath} from '../../../../utils';
import './index.scss';

const validate = values => {
    return {
        file: validateRequired(values.file),
        comment: validateRequired(values.comment) || validateMaxLength(300, values.comment)
    };
};

class FileUploadForm extends PureComponent {
    onSubmit = this.props.handleSubmit(formValues => {
        const {requestSaveFileUploadForm, importConfigId} = this.props;
        const {comment, file} = formValues;
        const requestPayload = {
            file,
            comment,
            file_name: getFileNameFromPath(file),
            import_config_id: importConfigId
        };
        return requestSaveFileUploadForm(requestPayload);
    });

    onManualFileUpload = async file => {
        const {url, errorMessage, isSuccess} = await this.props.requestManualFileUpload(file);
        const error = new Error(errorMessage);
        error.name = file.name;

        return isSuccess ? url : error;
    };

    render = () => {
        const {onCancel} = this.props;
        return (
            <div className='file-upload-form'>
                <Label className='plan-details-form-label' label='File Name' type='file'>
                    <Field
                        name='file'
                        component={FileUploader}
                        onUpload={this.onManualFileUpload}
                        maxCount={MANUAL_FILE_MAX_FILE_COUNT}
                        accept={ACCEPTABLE_TEXT_FORMATS}
                    />
                </Label>
                <Label type='input' label='Comment'>
                    <Field
                        name='comment'
                        component={Input}
                        placeholder='Enter notes here for your friends and colleagues...'
                    />
                </Label>
                <div className='file-upload-form-action-bar'>
                    <div className='file-upload-form-button'>
                        <Button onClick={onCancel}>cancel</Button>
                    </div>
                    <div className='file-upload-form-button'>
                        <Button onClick={this.onSubmit}>Upload &#38; Process</Button>
                    </div>
                </div>
            </div>
        );
    };
}

FileUploadForm.propTypes = {
    importConfigId: PropTypes.number.isRequired,
    requestSaveFileUploadForm: PropTypes.func.isRequired,
    requestManualFileUpload: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    onCancel: PropTypes.func
};

const mapDispatchToProps = dispatch => {
    return {
        requestSaveFileUploadForm: payload => dispatch(saveFileUploadForm(payload)),
        requestManualFileUpload: file => dispatch(uploadManualFile(file))
    };
};

export default compose(
    reduxForm({form: FORMS.fileUpload, validate}),
    connect(null, mapDispatchToProps),
    withRouter
)(FileUploadForm);

export {validate, FileUploadForm};
