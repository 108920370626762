import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Alert from '@frontend/ui-kit/Components/Alert';
import Icon from '@frontend/ui-kit/Components/Icon';
import Link from '@frontend/ui-kit/Components/Link';
import Table from '@frontend/ui-kit/Components/Table';
import {
    requestImportConfigs,
    deleteImportConfig,
    showManualFileUploadForConfigIdUpdated
} from '../../../actions/importConfigs';
import {getImportConfigs, getManualFileUploadShowConfigId} from '../../../selectors/importConfigs';
import ConfirmDeletePopup from '../../shared/ConfirmDeletePopup';
import UploadFilePopup from './UploadFilePopup';
import './index.scss';
import {IMPORT_METHODS, TPA_FILE_TYPE_OPTIONS} from '../../../constants';
import {getEqual} from '../../../utils';

class List extends PureComponent {
    state = {
        configId: null,
        confirmDelete: false,
        dataPurpose: '',
        dataSource: '',
        fileType: '',
        fileUploadDisabled: false,
        manualUploadEnabled: false
    };

    componentDidMount = () => {
        this.props.initImportConfigs();
    };

    handleDeleteOpen = config => {
        this.setState({
            confirmDelete: true,
            configId: config.id,
            dataSource: config.tpa_partner.name,
            fileType: config.tpa_file_type,
            dataPurpose: config.data_purpose
        });
    };

    handleDeleteClose = () => this.setState({confirmDelete: false});

    handleDeleteConfirm = () => {
        this.props.removeImportConfig(this.state.configId);
        this.handleDeleteClose();
    };

    handleFileUploadIconClick = config => {
        const isManualUpload = config.import_method === IMPORT_METHODS.manual;
        const {label: fileType} = TPA_FILE_TYPE_OPTIONS.find(getEqual(config.tpa_file_type, 'value'));
        const {tpa_partner: partner, data_purpose: dataPurpose, id: configId} = config;
        const {name: dataSource} = partner;

        this.setState({
            manualUploadEnabled: isManualUpload,
            configId,
            dataSource,
            fileType,
            dataPurpose
        });

        return isManualUpload ? this.props.showUploadForm(configId) : this.setState({fileUploadDisabled: true});
    };

    handleFileUploadClose = () => {
        this.setState({manualUploadEnabled: false});
        this.props.showUploadForm(null);
    };

    handleAlertClose = () => this.setState({fileUploadDisabled: false, configId: null});

    render = () => {
        const {confirmDelete, dataPurpose, dataSource, fileType, manualUploadEnabled, fileUploadDisabled, configId} =
            this.state;
        const {importConfigList, showManualFileUploadForConfigId} = this.props;
        const getTableColumns = () => {
            return [
                {
                    Header: 'Key',
                    accessor: 'id',
                    isHidden: true
                },
                {
                    Header: 'Data Source',
                    accessor: 'tpa_partner.name',
                    minWidth: 120
                },
                {
                    Header: 'File Type',
                    accessor: 'tpa_file_type',
                    minWidth: 120,
                    Cell: ({row}) => {
                        const config = importConfigList[row.id];
                        const {label: fileType} = TPA_FILE_TYPE_OPTIONS.find(getEqual(config.tpa_file_type, 'value'));

                        return fileType;
                    }
                },
                {
                    Header: 'Data Purpose',
                    accessor: 'data_purpose',
                    minWidth: 120
                },
                {
                    Header: 'Import Method',
                    accessor: 'import_method',
                    width: 120
                },
                {
                    Header: 'Action',
                    accessor: 'url',
                    isSortable: false,
                    Cell: ({row}) => {
                        const config = importConfigList[row.id];
                        const manualUploadIconClass = manualUploadEnabled ? 'icon-enabled' : 'icon-disabled';

                        return (
                            <div>
                                <div className='list-item-actions'>
                                    <Link href={`/import-configs/${config.id}/edit?id=${config.id}`}>
                                        <Icon type='edit' />
                                    </Link>
                                    <span
                                        className={`${manualUploadIconClass} item-upload`}
                                        onClick={() => this.handleFileUploadIconClick(config)}
                                    >
                                        <Icon type='upload' />
                                    </span>
                                    <span
                                        className='item-delete'
                                        onClick={() => {
                                            this.handleDeleteOpen(config);
                                        }}
                                    >
                                        <Icon type='trash' />
                                    </span>
                                </div>
                                {configId === config.id && fileUploadDisabled && (
                                    <Alert
                                        type='info'
                                        className='file-upload-disabled-alert'
                                        onClose={this.handleAlertClose}
                                    >
                                        Manual upload currently disabled in import configuration.
                                    </Alert>
                                )}
                            </div>
                        );
                    }
                }
            ];
        };

        const tableProps = {
            isFilterable: false,
            isSortable: true,
            columns: getTableColumns(),
            data: importConfigList.sort((a, b) => b.id - a.id),
            getRowProps: row => {
                return {key: row.id};
            }
        };
        const confirmText = `Delete import config: ${dataSource} / ${fileType} / ${dataPurpose}?`;
        const manualImportProps = {
            tpaPartnerName: dataSource,
            dataPurpose,
            fileType,
            isOpened: showManualFileUploadForConfigId,
            onClose: this.handleFileUploadClose,
            importConfigId: configId
        };

        return (
            <div className='import-configs-list'>
                <Table {...tableProps} />
                <ConfirmDeletePopup
                    isOpened={confirmDelete}
                    onClose={this.handleDeleteClose}
                    onDelete={this.handleDeleteConfirm}
                    text={confirmText}
                />
                {manualUploadEnabled && <UploadFilePopup {...manualImportProps} />}
            </div>
        );
    };
}

List.propTypes = {
    initImportConfigs: PropTypes.func.isRequired,
    removeImportConfig: PropTypes.func.isRequired,
    importConfigList: PropTypes.arrayOf(PropTypes.object),
    showManualFileUploadForConfigId: PropTypes.number,
    showUploadForm: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        importConfigList: getImportConfigs(state),
        showManualFileUploadForConfigId: getManualFileUploadShowConfigId(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initImportConfigs: () => dispatch(requestImportConfigs()),
        removeImportConfig: configId => dispatch(deleteImportConfig(configId)),
        showUploadForm: configId => dispatch(showManualFileUploadForConfigIdUpdated(configId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
