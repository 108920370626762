import {PARTNERS_RECEIVED, PARTNER_DELETED} from '../actions/actionTypes';

const initialState = {
    partnerList: []
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case PARTNERS_RECEIVED: {
            const {partners} = payload;

            return {...state, partnerList: partners};
        }

        case PARTNER_DELETED: {
            const {partnerId} = payload;

            const updatedPartners = state.partnerList.filter(partner => partner.id !== partnerId);

            return {...state, partnerList: updatedPartners};
        }

        default: {
            return state;
        }
    }
};
