import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import Heading from '@frontend/ui-kit/Components/Heading';
import Icon from '@frontend/ui-kit/Components/Icon';
import Input from '@frontend/ui-kit/Components/Input';
import Label from '@frontend/ui-kit/Components/Label';
import Row from '@frontend/ui-kit/Components/Row';
import Select from '@frontend/ui-kit/Components/Select';
import Text from '@frontend/ui-kit/Components/Text';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import {Form, Field} from '../../shared/FormComponents';
import Section from './Section';
import ExternalSftp from '../ExternalSftp';
import {getFormattedDataMapping, validateMaxLength, validateNotBlank, validateRequired, validateHeaderlessEntries} from '../../../utils';
import {redirectTo} from '../../../actions/general';
import {initImportConfigForm, saveImportConfigForm, saveExternalSftp} from '../../../actions/importConfigs';
import {
    FORMS,
    FORM_GLOBAL_ERROR,
    ROUTES,
    IMPORT_METHOD_OPTIONS,
    ROWS_PER_RECORD_VALUES,
    TPA_DATA_PURPOSES,
    TPA_FILE_TYPES,
    HEADERLESS_TOOLTIP,
    MARKETPLACE_TOOLTIP
} from '../../../constants';
import './index.scss';
import Footer from './Footer';
import Encryption from './Encryption';

const validate = values => ({
    tpa_partner_id: validateRequired(values.tpa_partner_id),
    import_method: validateRequired(values.import_method),
    claim_id_number: validateHeaderlessEntries(values.is_headerless, values.claim_id_number),
    claim_type: validateHeaderlessEntries(values.is_headerless, values.claim_type),
    line_number: validateHeaderlessEntries(values.is_headerless, values.line_number),
    data_source_internal_member_id: validateHeaderlessEntries(values.is_headerless, values.data_source_internal_member_id),
    medical_plan_member_id: 
        validateNotBlank(values.medical_plan_member_id) ||
        validateHeaderlessEntries(values.is_headerless, values.medical_plan_member_id),
    family_id: validateHeaderlessEntries(values.is_headerless, values.family_id),
    social_security_number: validateHeaderlessEntries(values.is_headerless, values.social_security_number),
    patient_first_name: 
        validateNotBlank(values.patient_first_name) ||
        validateHeaderlessEntries(values.is_headerless, values.patient_first_name),
    patient_last_name: 
        validateNotBlank(values.patient_last_name) ||
        validateHeaderlessEntries(values.is_headerless, values.patient_last_name),
    patient_date_of_birth: 
        validateNotBlank(values.patient_date_of_birth) ||
        validateHeaderlessEntries(values.is_headerless, values.patient_date_of_birth),
    relationship: 
        validateNotBlank(values.relationship) ||
        validateHeaderlessEntries(values.is_headerless, values.relationship),
    sex: 
        validateNotBlank(values.sex) ||
        validateHeaderlessEntries(values.is_headerless, values.sex),
    employer_name: validateHeaderlessEntries(values.is_headerless, values.employer_name),
    group_id: validateHeaderlessEntries(values.is_headerless, values.group_id),
    cpt_code: validateHeaderlessEntries(values.is_headerless, values.cpt_code),
    cpt_code_description: validateHeaderlessEntries(values.is_headerless, values.cpt_code_description),
    drg_code: validateHeaderlessEntries(values.is_headerless, values.drg_code),
    icd10: 
        validateNotBlank(values.icd10) ||
        validateHeaderlessEntries(values.is_headerless, values.icd10),
    icd10_description: validateHeaderlessEntries(values.is_headerless, values.icd10_description),
    service_quantity: validateHeaderlessEntries(values.is_headerless, values.service_quantity),
    modifier: validateHeaderlessEntries(values.is_headerless, values.modifier),
    date_of_service_from: validateHeaderlessEntries(values.is_headerless, values.date_of_service_from),
    date_of_service_to: validateHeaderlessEntries(values.is_headerless, values.date_of_service_to),
    provider_in_network: validateHeaderlessEntries(values.is_headerless, values.provider_in_network),
    billed_amount: validateHeaderlessEntries(values.is_headerless, values.billed_amount),
    allowed_amount: validateHeaderlessEntries(values.is_headerless, values.allowed_amount),
    savings_amount: validateHeaderlessEntries(values.is_headerless, values.savings_amount),
    ppo_allowed: validateHeaderlessEntries(values.is_headerless, values.ppo_allowed),
    ppo_savings: validateHeaderlessEntries(values.is_headerless, values.ppo_savings),
    amount_not_covered: validateHeaderlessEntries(values.is_headerless, values.amount_not_covered),
    coinsurance: validateHeaderlessEntries(values.is_headerless, values.coinsurance),
    copay: validateHeaderlessEntries(values.is_headerless, values.copay),
    ineligible: validateHeaderlessEntries(values.is_headerless, values.ineligible),
    deductible: validateHeaderlessEntries(values.is_headerless, values.deductible),
    paid_amount: validateHeaderlessEntries(values.is_headerless, values.paid_amount),
    paid_date: validateHeaderlessEntries(values.is_headerless, values.paid_date),
    processed_date: validateHeaderlessEntries(values.is_headerless, values.processed_date),
    adjustment: validateHeaderlessEntries(values.is_headerless, values.adjustment),
    facility: validateHeaderlessEntries(values.is_headerless, values.facility),
    facility_npi: validateHeaderlessEntries(values.is_headerless, values.facility_npi),
    facility_tax_id: validateHeaderlessEntries(values.is_headerless, values.facility_tax_id),
    provider_first_name: validateHeaderlessEntries(values.is_headerless, values.provider_first_name),
    provider_last_name: validateHeaderlessEntries(values.is_headerless, values.provider_last_name),
    phone_number: validateHeaderlessEntries(values.is_headerless, values.phone_number),
    provider_npi: validateHeaderlessEntries(values.is_headerless, values.provider_npi),
    provider_tin: validateHeaderlessEntries(values.is_headerless, values.provider_tin),
    provider_city: validateHeaderlessEntries(values.is_headerless, values.provider_city),
    provider_state: validateHeaderlessEntries(values.is_headerless, values.provider_state),
    provider_zip_code: validateHeaderlessEntries(values.is_headerless, values.provider_zip_code),
    provider_specialty: validateHeaderlessEntries(values.is_headerless, values.provider_specialty),
    specialty_description: validateHeaderlessEntries(values.is_headerless, values.specialty_description),
    place_of_service: validateHeaderlessEntries(values.is_headerless, values.place_of_service),
    internal_notes: validateMaxLength(300, values.internal_notes)
});

const MedicalClaimsEventAutomationForm = ({partnerOptions}) => {
    const dispatch = useDispatch();
    const {id: configId} = useParams();
    const [initialValues, setInitialValues] = useState({});
    const [importConfigId, setImportConfigId] = useState(configId);
    const [importMethod, setImportMethod] = useState();
    const [isEdit, setIsEdit] = useState(!!importConfigId);
    const [isHeaderless, setIsHeaderless] = useState(false);
    const [isMarketplaceIdentifiable, setIsMarketplaceIdentifiable] = useState(false);
    const [isEncrypted, setIsEncrypted] = useState();
    const [isExternalSftp, setIsExternalSftp] = useState();

    const onSubmit = useCallback(
        async values => {
            const {
                id: configId,
                tpa_partner: tpaPartner,
                tpa_file_type: tpaFileType,
                data_purpose: dataPurpose,
                partnerOptions: partners,
                data_mappings: dataMappings,
                tpa_partner_id: tpaPartnerId,
                import_method: importMethod,
                has_multiple_rows_per_record: rowsPerRecord,
                join_key: joinKey,
                internal_notes: internalNotes,
                is_headerless: isHeaderless,
                is_marketplace_identifiable: isMarketplaceIdentifiable,
                is_encrypted_file: isEncrypted,
                vault_path: vaultPath,
                is_external_sftp: isExternalSftp,
                external_sftp_id: externalSftpId,
                external_sftp: {
                    credentials_link: credentialsLink,
                    directory_path: directoryPath,
                    filename_pattern: filenamePattern,
                    sync_frequency_in_hours: syncFrequencyInHours,
                    last_sync: lastSync
                } = {},
                ...dataMappingsFormValues
            } = values;

            const formDataMappings = Object.entries(dataMappingsFormValues).map(([fieldName, fieldValue]) => ({
                healthjoy_field: fieldName,
                data_field: fieldValue
            }));
            const formattedDataMappings = formDataMappings.map(getFormattedDataMapping).flat();

            const filterOutEmpties = mapping => {
                const {data_field: fieldValue} = mapping;

                return !!fieldValue;
            };

            const validDataMappings = formattedDataMappings.filter(filterOutEmpties);
            const importConfigRequestParams = {
                tpa_file_type: TPA_FILE_TYPES.medicalClaims,
                data_purpose: TPA_DATA_PURPOSES.eventAutomation,
                tpa_partner_id: tpaPartnerId,
                import_method: importMethod,
                join_key: rowsPerRecord === ROWS_PER_RECORD_VALUES.multipleRowsPerRecord ? joinKey : null,
                data_mappings: validDataMappings,
                is_headerless: isHeaderless || false,
                is_marketplace_identifiable: isMarketplaceIdentifiable || false,
                is_encrypted_file: isEncrypted,
                vault_path: vaultPath || null,
                is_external_sftp: importMethod === 'Manual Upload' ? false : isExternalSftp,
                external_sftp_id: externalSftpId || null,
                internal_notes: internalNotes || null
            };

            if (isExternalSftp && importMethod === 'SFTP') {
                const todaysDate = new Date().toISOString();

                const externalSftpRequestParams = {
                    credentials_link: credentialsLink,
                    directory_path: directoryPath || '',
                    filename_pattern: filenamePattern,
                    sync_frequency_in_hours: syncFrequencyInHours,
                    last_sync: lastSync || todaysDate
                };

                const {data, isSuccess, submissionGlobalError} = await dispatch(
                    saveExternalSftp(externalSftpId, {...externalSftpRequestParams})
                );

                if (!isSuccess) {
                    return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
                }

                importConfigRequestParams.external_sftp_id = data.id;
            }

            const {data, isSuccess, isEdit, submissionGlobalError} = await dispatch(
                saveImportConfigForm(configId, {...importConfigRequestParams})
            );
            const {id: importConfigId} = data;

            if (!isSuccess) {
                return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
            }

            setInitialValues(values);
            setIsEdit(isEdit);
            setImportConfigId(importConfigId);
            setIsHeaderless(isHeaderless);
            setIsMarketplaceIdentifiable(isMarketplaceIdentifiable);
        },
        [dispatch]
    );

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(initImportConfigForm(importConfigId));
            const {
                is_encrypted_file: isEncrypted,
                is_external_sftp: isExternalSftp,
                import_method: importMethod
            } = data;

            setInitialValues(data);
            setIsEncrypted(isEncrypted);
            setIsExternalSftp(isExternalSftp);
            setImportMethod(importMethod);
        })();
    }, [dispatch, importConfigId]);

    const onSubmitSuccess = useCallback(async () => {
        if (!isEdit) {
            dispatch(redirectTo(`${ROUTES.importConfigs}/${importConfigId}/edit?id=${importConfigId}`));
        }
    }, [dispatch, importConfigId, isEdit]);

    const onImportMethodChange = value => {
        setImportMethod(value);

        if (importMethod === 'Manual Upload') {
            setIsExternalSftp(false);
        }
    };

    const onHeaderlessChange = (event, form) => {
        event.persist();
        setIsHeaderless(!isHeaderless);
        form.change('is_headerless', !isHeaderless);
    };

    const headerlessTooltipText = <Text className='information-paragraph'>{HEADERLESS_TOOLTIP.body}</Text>;

    const headerlessTooltip = (
        <div>
            Headerless
            <Tooltip isClickable content={headerlessTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );
    
    const onMarketplaceIdentifiableChange = (event, form) => {
        event.persist();
        setIsMarketplaceIdentifiable(isMarketplaceIdentifiable => !isMarketplaceIdentifiable);
        form.change('is_marketplace_identifiable', !isMarketplaceIdentifiable);
    };

    const marketplaceTooltipText = <Text className='information-paragraph'>{MARKETPLACE_TOOLTIP.body}</Text>;

    const marketplaceTooltip = (
        <div>
            HealthJoy-generated Identifier
            <Tooltip isClickable content={marketplaceTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    return (
        <div className='import-config-form'>
            <Column offset={0} sm={12}>
                <Form
                    name={FORMS.medicalClaimsImportConfig}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validate={validate}
                    onSubmitSuccess={onSubmitSuccess}
                >
                    {({handleSubmit, submitError}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Section header='Data Source' offset={0} sm={12}>
                                    <Row className='import-config-form-upper-margin'>
                                        <Column sm={4}>
                                            <Label label='Source' type='select'>
                                                <Field name='tpa_partner_id'>
                                                    {props => <Select {...props} options={partnerOptions} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Import Method' type='select'>
                                                <Field name='import_method' onChange={onImportMethodChange}>
                                                    {props => <Select {...props} options={IMPORT_METHOD_OPTIONS} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        {importMethod === 'SFTP' && <ExternalSftp isExternalSftp={isExternalSftp} />}
                                        <Encryption isEncrypted={isEncrypted} />
                                    </Row>
                                </Section>
                                <Section header='Data Mapping' offset={0} sm={12} displaySeparator={false}>
                                    <Row className='import-config-form-upper-margin'>
                                        <Column sm={4}>
                                            <Label label={headerlessTooltip} type='checkbox'>
                                                <Field name='is_headerless' onChange={onHeaderlessChange}>
                                                    {props => <Checkbox {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label={marketplaceTooltip} type='checkbox'>
                                                <Field name='is_marketplace_identifiable' onChange={onMarketplaceIdentifiableChange}>
                                                    {props => <Checkbox {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                    </Row>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Claim information
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Claim ID Number' type='input'>
                                                    <Field name='claim_id_number'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Claim Type' type='input'>
                                                    <Field name='claim_type'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Line Number' type='input'>
                                                    <Field name='line_number'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Member information
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Internal Data Source Member ID' type='input'>
                                                    <Field name='data_source_internal_member_id'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Medical Plan Member ID' type='input'>
                                                    <Field name='medical_plan_member_id'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Family ID' type='input'>
                                                    <Field name='family_id'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Social Security Number' type='input'>
                                                    <Field name='social_security_number'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Patient First Name' type='input'>
                                                    <Field name='patient_first_name'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Patient Last Name' type='input'>
                                                    <Field name='patient_last_name'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Patient Date of Birth' type='input'>
                                                    <Field name='patient_date_of_birth'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Relationship' type='input'>
                                                    <Field name='relationship'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Sex/Gender' type='input'>
                                                    <Field name='sex'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Employer Name' type='input'>
                                                    <Field name='employer_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Group ID' type='input'>
                                                    <Field name='group_id'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Procedure & service
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='CPT Code' type='input'>
                                                    <Field name='cpt_code'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='CPT Code Description' type='input'>
                                                    <Field name='cpt_code_description'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='DRG Code' type='input'>
                                                    <Field name='drg_code'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='ICD-10 Code(s)' type='input'>
                                                    <Field name='icd10'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='ICD-10 Description(s)' type='input'>
                                                    <Field name='icd10_description'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Service Quantity' type='input'>
                                                    <Field name='service_quantity'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Modifier' type='input'>
                                                    <Field name='modifier'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Date of Service From' type='input'>
                                                    <Field name='date_of_service_from'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Date of Service to' type='input'>
                                                    <Field name='date_of_service_to'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Billing information
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Provider In-Network' type='input'>
                                                    <Field name='provider_in_network'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Billed Amount' type='input'>
                                                    <Field name='billed_amount'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Allowed Amount' type='input'>
                                                    <Field name='allowed_amount'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Savings Amount' type='input'>
                                                    <Field name='savings_amount'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='PPO Allowed Amount' type='input'>
                                                    <Field name='ppo_allowed'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='PPO Savings Amount' type='input'>
                                                    <Field name='ppo_savings'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Amount Not Covered' type='input'>
                                                    <Field name='amount_not_covered'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Coinsurance' type='input'>
                                                    <Field name='coinsurance'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Copay' type='input'>
                                                    <Field name='copay'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Ineligible' type='input'>
                                                    <Field name='ineligible'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Deductible' type='input'>
                                                    <Field name='deductible'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Paid Amount' type='input'>
                                                    <Field name='paid_amount'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Paid Date' type='input'>
                                                    <Field name='paid_date'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Processed Date' type='input'>
                                                    <Field name='processed_date'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Adjustment' type='input'>
                                                    <Field name='adjustment'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Facility & provider
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Facility Name' type='input'>
                                                    <Field name='facility'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Facility NPI' type='input'>
                                                    <Field name='facility_npi'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Facility Tax ID' type='input'>
                                                    <Field name='facility_tax_id'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Provider First Name' type='input'>
                                                    <Field name='provider_first_name'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Provider Last Name' type='input'>
                                                    <Field name='provider_last_name'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Phone Number' type='input'>
                                                    <Field name='phone_number'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Provider NPI' type='input'>
                                                    <Field name='provider_npi'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Provider Tax ID' type='input'>
                                                    <Field name='provider_tin'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Provider City' type='input'>
                                                    <Field name='provider_city'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='State' type='input'>
                                                    <Field name='provider_state'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Zip Code' type='input'>
                                                    <Field name='provider_zip_code'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Specialty' type='input'>
                                                    <Field name='provider_specialty'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Specialty Description' type='input'>
                                                    <Field name='specialty_description'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Place of Service (POS Code)' type='input'>
                                                    <Field name='place_of_service'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                </Section>
                                <Footer importConfigId={importConfigId} submitError={submitError} />
                            </form>
                        );
                    }}
                </Form>
            </Column>
        </div>
    );
};

MedicalClaimsEventAutomationForm.propTypes = {
    partnerOptions: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.number}))
};

export {MedicalClaimsEventAutomationForm as TestableMedicalClaimsEventAutomationForm};
export default React.memo(MedicalClaimsEventAutomationForm);
