import React from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import classNames from 'classnames';
import Link from '@frontend/ui-kit/Components/Link';
import './index.scss';

const getNavigationSubItem = (categoryPath, currPath) => subItem => {
    const {title, path} = subItem;
    const isActive = path === currPath;
    const className = classNames('navigation-sub-items__item', {'navigation-sub-items__item_active': isActive});

    return (
        <div key={path} className={className}>
            <Link href={`/${categoryPath}/${path}`}>{title}</Link>
        </div>
    );
};

const NavigationItem = props => {
    const {path: categoryPath, title, subItems} = props;
    const location = useLocation();
    const [currCategoryPath, currPath] = location.pathname.split('/').filter(Boolean);
    const isActive = categoryPath === currCategoryPath;
    const className = classNames('navigation-item', {'navigation-item_active': isActive});

    return (
        <div className={className}>
            {subItems ? title : <Link href={`/${categoryPath}`}>{title}</Link>}

            {subItems && (
                <div className='navigation-sub-items'>{subItems.map(getNavigationSubItem(categoryPath, currPath))}</div>
            )}
        </div>
    );
};

NavigationItem.propTypes = {
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subItems: PropTypes.arrayOf(
        PropTypes.exact({
            path: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired
        })
    )
};

export default NavigationItem;
