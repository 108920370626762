import React from 'react';
import Header from '../Components/shared/Header';
import PlatformLayout from '../Components/shared/PlatformLayout';

const withPlatformLayout = Component => {
    return props => {
        return (
            <React.Fragment>
                <Header />

                <PlatformLayout>
                    <Component {...props} />
                </PlatformLayout>
            </React.Fragment>
        );
    };
};

export default withPlatformLayout;
