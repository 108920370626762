import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import './index.scss';

const Layout = ({children}) => {
    return (
        <Row className='authorization-layout' center='sm' middle='sm'>
            <Column sm={6}>
                <ContentSection className='layout-content'>{children}</ContentSection>
            </Column>
        </Row>
    );
};

Layout.propTypes = {
    children: PropTypes.node
};

export default Layout;
