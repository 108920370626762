import React from 'react';
import Layout from '../../../Components/Error/Layout';
import NotFound from '../../../Components/Error/NotFound';

const PageNotFound = () => {
    return (
        <Layout>
            <NotFound />
        </Layout>
    );
};

export default PageNotFound;
