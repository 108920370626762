import React from 'react';
import PageHeading from '../../../Components/shared/PageHeading';
import PageLayout from '../../../Components/shared/PageLayout';
import PartnerForm from '../../../Components/Partners/Form';

const Edit = () => {
    return (
        <React.Fragment>
            <PageHeading>Edit Data Source</PageHeading>

            <PageLayout>
                <PartnerForm />
            </PageLayout>
        </React.Fragment>
    );
};

export default Edit;
