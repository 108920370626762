import Login from './Authorization/Login';
import PageNotFound from './Error/PageNotFound';
import ServerError from './Error/ServerError';
import PartnersCreate from './Partners/Create';
import PartnersEdit from './Partners/Edit';
import PartnersList from './Partners/List';
import ImportConfigsCreate from './ImportConfigs/Create';
import ImportConfigsEdit from './ImportConfigs/Edit';
import ImportConfigsList from './ImportConfigs/List';

export default {
    Login,
    PageNotFound,
    ServerError,
    PartnersCreate,
    PartnersEdit,
    PartnersList,
    ImportConfigsCreate,
    ImportConfigsEdit,
    ImportConfigsList
};
