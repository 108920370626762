export const FINISH_LOADING = 'FINISH_LOADING';
export const START_LOADING = 'START_LOADING';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const OPEN_POPUP = 'OPEN_POPUP';
export const SET_DIRTY_FORMS = 'SET_DIRTY_FORMS';

export const UNAUTHORIZE = 'UNAUTHORIZE';
export const AUTHORIZE = 'AUTHORIZE';

export const PARTNERS_RECEIVED = 'PARTNERS_RECEIVED';
export const PARTNER_CREATED = 'PARTNER_CREATED';
export const PARTNER_UPDATED = 'PARTNER_UPDATED';
export const PARTNER_DELETED = 'PARTNER_DELETED';

export const IMPORT_CONFIG_FIELDS_RECEIVED = 'IMPORT_CONFIG_FIELDS_RECEIVED';
export const IMPORT_CONFIGS_RECEIVED = 'IMPORT_CONFIGS_RECEIVED';
export const IMPORT_CONFIG_RECEIVED = 'IMPORT_CONFIG_RECEIVED';
export const IMPORT_CONFIG_CREATED = 'IMPORT_CONFIG_CREATED';
export const IMPORT_CONFIG_UPDATED = 'IMPORT_CONFIG_UPDATED';
export const IMPORT_CONFIG_DELETED = 'IMPORT_CONFIG_DELETED';

export const EXTERNAL_SFTP_RECEIVED = 'EXTERNAL_SFTP_RECEIVED';
export const EXTERNAL_SFTP_CREATED = 'EXTERNAL_SFTP_CREATED';
export const EXTERNAL_SFTP_UPDATED = 'EXTERNAL_SFTP_UPDATED';
export const EXTERNAL_SFTP_DELETED = 'EXTERNAL_SFTP_DELETED';

export const SET_IMPORT_CONFIG_SUCCESS_STATUS = 'SET_IMPORT_CONFIG_SUCCESS_STATUS';
export const SET_EXTERNAL_SFTP_SUCCESS_STATUS = 'SET_EXTERNAL_SFTP_SUCCESS_STATUS';

export const SET_SHOW_MANUAL_FILE_UPLOAD = 'SET_SHOW_MANUAL_FILE_UPLOAD';
