import {ENV, ENVS, MULTI_ENV_DEV_PREFIX} from '../constants';

const AUTH_ENDPOINT = '/auth';

export const TOKEN_ENDPOINT = `${AUTH_ENDPOINT}/login`;
export const TOKEN_REFRESH_ENDPOINT = `${AUTH_ENDPOINT}/refresh`;

export const PARTNERS_ENDPOINT = '/tpa-partners';

export const IMPORT_CONFIG_FIELDS_ENDPOINT = '/import-config-fields';

export const IMPORT_CONFIGS_ENDPOINT = '/import-configs';

export const EXTERNAL_SFTPS_ENDPOINT = '/external-sftp';

export const MANUAL_FILES_ENDPOINT = '/manual-files';

export const HJ_CDN_BASE_URL = {
    [ENVS.dev]: `https://cdn.${MULTI_ENV_DEV_PREFIX}.hjoy.net/api/v1`,
    [ENVS.stage]: 'https://cdn.hj01-stage.hjoy.net/api/v1',
    [ENVS.production]: 'https://cdn.healthjoy.com/api/v1'
}[ENV];
