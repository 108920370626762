import {FINISH_LOADING, START_LOADING, CLOSE_POPUP, OPEN_POPUP, SET_DIRTY_FORMS} from './actionTypes';

export const startLoading = isLoader => ({type: START_LOADING, isLoader});

export const finishLoading = () => ({type: FINISH_LOADING});

export const openPopup = params => ({type: OPEN_POPUP, ...params});

export const closePopup = ({id}) => ({type: CLOSE_POPUP, id});

export const setDirtyForms = dirtyForms => ({type: SET_DIRTY_FORMS, dirtyForms});
