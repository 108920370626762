import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import Heading from '@frontend/ui-kit/Components/Heading';
import Icon from '@frontend/ui-kit/Components/Icon';
import Input from '@frontend/ui-kit/Components/Input';
import Label from '@frontend/ui-kit/Components/Label';
import Row from '@frontend/ui-kit/Components/Row';
import Select from '@frontend/ui-kit/Components/Select';
import Text from '@frontend/ui-kit/Components/Text';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Footer from './Footer';
import {Form, Field} from '../../shared/FormComponents';
import Section from './Section';
import ExternalSftp from '../ExternalSftp';
import Encryption from './Encryption';
import {getFormattedDataMapping, validateMaxLength, validateRequired, validateHeaderlessEntries} from '../../../utils';
import {redirectTo} from '../../../actions/general';
import {initImportConfigForm, saveImportConfigForm, saveExternalSftp} from '../../../actions/importConfigs';
import {
    FORMS,
    FORM_GLOBAL_ERROR,
    ROUTES,
    IMPORT_METHOD_OPTIONS,
    ROWS_PER_RECORD_VALUES,
    TPA_DATA_PURPOSES,
    TPA_FILE_TYPES,
    HEADERLESS_TOOLTIP,
    MARKETPLACE_TOOLTIP
} from '../../../constants';
import './index.scss';

const validate = values => ({
    tpa_partner_id: validateRequired(values.tpa_partner_id),
    import_method: validateRequired(values.import_method),
    carrier: validateHeaderlessEntries(values.is_headerless, values.carrier),
    claim_id: validateHeaderlessEntries(values.is_headerless, values.claim_id),
    line_id: validateHeaderlessEntries(values.is_headerless, values.line_id),
    group_number: validateHeaderlessEntries(values.is_headerless, values.group_number),
    group_name: validateHeaderlessEntries(values.is_headerless, values.group_name),
    member_id: validateHeaderlessEntries(values.is_headerless, values.member_id),
    ssn: validateHeaderlessEntries(values.is_headerless, values.ssn),
    subscriber_first_name: validateHeaderlessEntries(values.is_headerless, values.subscriber_first_name),
    subscriber_last_name: validateHeaderlessEntries(values.is_headerless, values.subscriber_last_name),
    patient_fist_name: validateHeaderlessEntries(values.is_headerless, values.patient_fist_name),
    patient_last_name: validateHeaderlessEntries(values.is_headerless, values.patient_last_name),
    relationship: validateHeaderlessEntries(values.is_headerless, values.relationship),
    patient_gender: validateHeaderlessEntries(values.is_headerless, values.patient_gender),
    patient_date_of_birth: validateHeaderlessEntries(values.is_headerless, values.patient_date_of_birth),
    date_of_service: validateHeaderlessEntries(values.is_headerless, values.date_of_service),
    icd10: validateHeaderlessEntries(values.is_headerless, values.icd10),
    national_drug_code: validateHeaderlessEntries(values.is_headerless, values.national_drug_code),
    procedure_code: validateHeaderlessEntries(values.is_headerless, values.procedure_code),
    drug_form: validateHeaderlessEntries(values.is_headerless, values.drug_form),
    drug_label: validateHeaderlessEntries(values.is_headerless, values.drug_label),
    drug_strength: validateHeaderlessEntries(values.is_headerless, values.drug_strength),
    dose_quantity: validateHeaderlessEntries(values.is_headerless, values.dose_quantity),
    generic_name: validateHeaderlessEntries(values.is_headerless, values.generic_name),
    generic_or_brand: validateHeaderlessEntries(values.is_headerless, values.generic_or_brand),
    number_days_supply: validateHeaderlessEntries(values.is_headerless, values.number_days_supply),
    dea_schedule: validateHeaderlessEntries(values.is_headerless, values.dea_schedule),
    prescribing_provider_npi: validateHeaderlessEntries(values.is_headerless, values.prescribing_provider_npi),
    pharmacy_name: validateHeaderlessEntries(values.is_headerless, values.pharmacy_name),
    pharmacy_provider_npi: validateHeaderlessEntries(values.is_headerless, values.pharmacy_provider_npi),
    submitted_cost: validateHeaderlessEntries(values.is_headerless, values.submitted_cost),
    tax: validateHeaderlessEntries(values.is_headerless, values.tax),
    patient_cost: validateHeaderlessEntries(values.is_headerless, values.patient_cost),
    ncpdp_reject_code: validateHeaderlessEntries(values.is_headerless, values.ncpdp_reject_code),
    formulary_indicator: validateHeaderlessEntries(values.is_headerless, values.formulary_indicator),
    charge_amount: validateHeaderlessEntries(values.is_headerless, values.charge_amount),
    allowed_amount: validateHeaderlessEntries(values.is_headerless, values.allowed_amount),
    copayment_amount: validateHeaderlessEntries(values.is_headerless, values.copayment_amount),
    coinsurance_amount: validateHeaderlessEntries(values.is_headerless, values.coinsurance_amount),
    deductible_amount: validateHeaderlessEntries(values.is_headerless, values.deductible_amount),
    internal_notes: validateMaxLength(300, values.internal_notes)
});

const PharmacyClaimsForm = ({partnerOptions}) => {
    const dispatch = useDispatch();
    const {id: configId} = useParams();
    const [initialValues, setInitialValues] = useState({});
    const [importConfigId, setImportConfigId] = useState(configId);
    const [importMethod, setImportMethod] = useState();
    const [isEdit, setIsEdit] = useState(!!importConfigId);
    const [isHeaderless, setIsHeaderless] = useState(false);
    const [isMarketplaceIdentifiable, setIsMarketplaceIdentifiable] = useState(false);
    const [isEncrypted, setIsEncrypted] = useState();
    const [isExternalSftp, setIsExternalSftp] = useState();

    const onSubmit = useCallback(
        async values => {
            const {
                id: configId,
                tpa_partner: tpaPartner,
                tpa_file_type: tpaFileType,
                data_purpose: dataPurpose,
                partnerOptions: partners,
                data_mappings: dataMappings,
                tpa_partner_id: tpaPartnerId,
                import_method: importMethod,
                has_multiple_rows_per_record: rowsPerRecord,
                join_key: joinKey,
                internal_notes: internalNotes,
                is_headerless: isHeaderless,
                is_marketplace_identifiable: isMarketplaceIdentifiable,
                is_encrypted_file: isEncrypted,
                vault_path: vaultPath,
                is_external_sftp: isExternalSftp,
                external_sftp_id: externalSftpId,
                external_sftp: {
                    credentials_link: credentialsLink,
                    directory_path: directoryPath,
                    filename_pattern: filenamePattern,
                    sync_frequency_in_hours: syncFrequencyInHours,
                    last_sync: lastSync
                } = {},
                ...dataMappingsFormValues
            } = values;

            const formDataMappings = Object.entries(dataMappingsFormValues).map(([fieldName, fieldValue]) => ({
                healthjoy_field: fieldName,
                data_field: fieldValue
            }));
            const formattedDataMappings = formDataMappings.map(getFormattedDataMapping).flat();

            const filterOutEmpties = mapping => {
                const {data_field: fieldValue} = mapping;

                return !!fieldValue;
            };

            const validDataMappings = formattedDataMappings.filter(filterOutEmpties);
            const importConfigRequestParams = {
                tpa_file_type: TPA_FILE_TYPES.pharmacyClaims,
                data_purpose: TPA_DATA_PURPOSES.dataStorage,
                tpa_partner_id: tpaPartnerId,
                import_method: importMethod,
                join_key: rowsPerRecord === ROWS_PER_RECORD_VALUES.multipleRowsPerRecord ? joinKey : null,
                data_mappings: validDataMappings,
                is_headerless: isHeaderless || false,
                is_marketplace_identifiable: isMarketplaceIdentifiable || false,
                is_encrypted_file: isEncrypted,
                vault_path: vaultPath || null,
                is_external_sftp: importMethod === 'Manual Upload' ? false : isExternalSftp,
                external_sftp_id: externalSftpId || null,
                internal_notes: internalNotes || null
            };

            if (isExternalSftp && importMethod === 'SFTP') {
                const todaysDate = new Date().toISOString();

                const externalSftpRequestParams = {
                    credentials_link: credentialsLink,
                    directory_path: directoryPath || '',
                    filename_pattern: filenamePattern,
                    sync_frequency_in_hours: syncFrequencyInHours,
                    last_sync: lastSync || todaysDate
                };

                const {data, isSuccess, submissionGlobalError} = await dispatch(
                    saveExternalSftp(externalSftpId, {...externalSftpRequestParams})
                );

                if (!isSuccess) {
                    return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
                }

                importConfigRequestParams.external_sftp_id = data.id;
            }

            const {data, isSuccess, isEdit, submissionGlobalError} = await dispatch(
                saveImportConfigForm(configId, {...importConfigRequestParams})
            );
            const {id: importConfigId} = data;

            if (!isSuccess) {
                return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
            }

            setInitialValues(values);
            setIsEdit(isEdit);
            setImportConfigId(importConfigId);
            setIsHeaderless(isHeaderless);
            setIsMarketplaceIdentifiable(isMarketplaceIdentifiable);
        },
        [dispatch]
    );

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(initImportConfigForm(importConfigId));
            const {
                is_encrypted_file: isEncrypted,
                is_external_sftp: isExternalSftp,
                import_method: importMethod
            } = data;

            setInitialValues(data);
            setIsEncrypted(isEncrypted);
            setIsExternalSftp(isExternalSftp);
            setImportMethod(importMethod);
        })();
    }, [dispatch, importConfigId]);

    const onSubmitSuccess = useCallback(async () => {
        if (!isEdit) {
            dispatch(redirectTo(`${ROUTES.importConfigs}/${importConfigId}/edit?id=${importConfigId}`));
        }
    }, [dispatch, importConfigId, isEdit]);

    const onImportMethodChange = value => {
        setImportMethod(value);

        if (importMethod === 'Manual Upload') {
            setIsExternalSftp(false);
        }
    };

    const onHeaderlessChange = (event, form) => {
        event.persist();
        setIsHeaderless(!isHeaderless);
        form.change('is_headerless', !isHeaderless);
    };

    const headerlessTooltipText = <Text className='information-paragraph'>{HEADERLESS_TOOLTIP.body}</Text>;

    const headerlessTooltip = (
        <div>
            Headerless
            <Tooltip maxWidth='none' isClickable content={headerlessTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    const onMarketplaceIdentifiableChange = (event, form) => {
        event.persist();
        setIsMarketplaceIdentifiable(isMarketplaceIdentifiable => !isMarketplaceIdentifiable);
        form.change('is_marketplace_identifiable', !isMarketplaceIdentifiable);
    };

    const marketplaceTooltipText = <Text className='information-paragraph'>{MARKETPLACE_TOOLTIP.body}</Text>;

    const marketplaceTooltip = (
        <div>
            HealthJoy-generated Identifier
            <Tooltip isClickable content={marketplaceTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    return (
        <div className='import-config-form'>
            <Column offset={0} sm={12}>
                <Form
                    name={FORMS.pharmacyClaimsImportConfig}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validate={validate}
                    onSubmitSuccess={onSubmitSuccess}
                >
                    {({handleSubmit, submitError}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Section header='Data Source' offset={0} sm={12}>
                                    <Row className='import-config-form-upper-margin'>
                                        <Column sm={4}>
                                            <Label label='Source' type='select'>
                                                <Field name='tpa_partner_id'>
                                                    {props => <Select {...props} options={partnerOptions} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Import Method' type='select'>
                                                <Field name='import_method' onChange={onImportMethodChange}>
                                                    {props => <Select {...props} options={IMPORT_METHOD_OPTIONS} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        {importMethod === 'SFTP' && <ExternalSftp isExternalSftp={isExternalSftp} />}
                                        <Encryption isEncrypted={isEncrypted} />
                                    </Row>
                                </Section>
                                <Section header='Data Mapping' offset={0} sm={12} displaySeparator={false}>
                                    <Row className='import-config-form-upper-margin'>
                                        <Column sm={4}>
                                            <Label label={headerlessTooltip} type='checkbox'>
                                                <Field name='is_headerless' onChange={onHeaderlessChange}>
                                                    {props => <Checkbox {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label={marketplaceTooltip} type='checkbox'>
                                                <Field name='is_marketplace_identifiable' onChange={onMarketplaceIdentifiableChange}>
                                                    {props => <Checkbox {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                    </Row>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Claim information
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Carrier' type='input'>
                                                    <Field name='carrier'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Claim ID' type='input'>
                                                    <Field name='claim_id'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Line ID / Number' type='input'>
                                                    <Field name='line_id'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Group Number' type='input'>
                                                    <Field name='group_number'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Group Name' type='input'>
                                                    <Field name='group_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Personal Information
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Member ID' type='input'>
                                                    <Field name='member_id'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Member SSN' type='input'>
                                                    <Field name='ssn'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Subscriber First Name' type='input'>
                                                    <Field name='subscriber_first_name'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Subscriber Last Name' type='input'>
                                                    <Field name='subscriber_last_name'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Patient First Name' type='input'>
                                                    <Field name='patient_first_name'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Patient Last Name' type='input'>
                                                    <Field name='patient_last_name'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Relationship' type='input'>
                                                    <Field name='relationship'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Patient Gender' type='input'>
                                                    <Field name='patient_gender'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Patient Date of Birth' type='input'>
                                                    <Field name='patient_date_of_birth'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Date of Service' type='input'>
                                                    <Field name='date_of_service'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Drug Information
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='ICD-10 Diagnosis Code(s)' type='input'>
                                                    <Field name='icd10'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='National Drug Code' type='input'>
                                                    <Field name='national_drug_code'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Procedure Code' type='input'>
                                                    <Field name='procedure_code'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Drug Label/Trade Name' type='input'>
                                                    <Field name='drug_label'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Drug Strength' type='input'>
                                                    <Field name='drug_strength'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Generic Name' type='input'>
                                                    <Field name='generic_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Generic or Brand' type='input'>
                                                    <Field name='generic_or_brand'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label="Number Days' Supply" type='input'>
                                                    <Field name='number_days_supply'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Form' type='input'>
                                                    <Field name='drug_form'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Dose Quantity' type='input'>
                                                    <Field name='dose_quantity'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='DEA Schedule' type='input'>
                                                    <Field name='dea_schedule'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Billing information
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Prescribing Provider NPI' type='input'>
                                                    <Field name='prescribing_provider_npi'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Pharmacy Name' type='input'>
                                                    <Field name='pharmacy_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Pharmacy Provider NPI' type='input'>
                                                    <Field name='pharmacy_provider_npi'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Submitted Cost' type='input'>
                                                    <Field name='submitted_cost'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Tax' type='input'>
                                                    <Field name='tax'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Patient Cost' type='input'>
                                                    <Field name='patient_cost'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='NCPDP Reject Code' type='input'>
                                                    <Field name='ncpdp_reject_code'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Formulary Indicator' type='input'>
                                                    <Field name='formulary_indicator'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Charge Amount' type='input'>
                                                    <Field name='charge_amount'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Allowed Amount' type='input'>
                                                    <Field name='allowed_amount'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Copayment Amount' type='input'>
                                                    <Field name='copayment_amount'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Coinsurance amount' type='input'>
                                                    <Field name='coinsurance_amount'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Deductible Amount' type='input'>
                                                    <Field name='deductible_amount'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                </Section>
                                <Footer importConfigId={importConfigId} submitError={submitError} />
                            </form>
                        );
                    }}
                </Form>
            </Column>
        </div>
    );
};

PharmacyClaimsForm.propTypes = {
    partnerOptions: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.number}))
};

export default React.memo(PharmacyClaimsForm);
