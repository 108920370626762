import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Label from '@frontend/ui-kit/Components/Label';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Icon from '@frontend/ui-kit/Components/Icon';
import Text from '@frontend/ui-kit/Components/Text';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import {Field} from '../../shared/FormComponents';
import {validateRequired} from '../../../utils';
import './index.scss';

const Encryption = props => {
    const [isEncrypted, setIsEncrypted] = useState();

    useEffect(() => {
        setIsEncrypted(props.isEncrypted);
    }, [props.isEncrypted]);

    const onEncryptionChange = event => {
        event.persist();
        setIsEncrypted(!isEncrypted);
    };
    
    const ENCRYPTION_TOOLTIP = {
        body: 'Is this an encrypted file?'
    };

    const composeValidators =
        (...validators) =>
        value =>
            validators.reduce((error, validator) => error || validator(value), undefined);

    const encryptionTooltipText = <Text className='information-paragraph'>{ENCRYPTION_TOOLTIP.body}</Text>;

    const encryptionTooltip = (
        <div>
            Encrypted
            <Tooltip maxWidth="none" isClickable content={encryptionTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    return (
        <React.Fragment>
            <Column sm={12} className='checkbox'>
                <Label label={encryptionTooltip}>
                    <Field name='is_encrypted_file' type='Checkbox' data-testid="encryption_checkbox" checked={isEncrypted} onChange={onEncryptionChange}>
                        {props => <Checkbox {...props} />}
                    </Field>
                </Label>
            </Column>
            <Column sm={6}>
                {isEncrypted && (
                    <Label label='Credentials Link' type='input'>
                        <Field name='vault_path' validate={composeValidators(validateRequired)} data-testid="credentials_link_input">
                            {props => <Input {...props} />}
                        </Field>
                    </Label>
                )}
            </Column>
        </React.Fragment>
    );
};

Encryption.propTypes = {
    isEncrypted: PropTypes.bool
};

Encryption.defaultProps = {
    isEncrypted: false
};

export default React.memo(Encryption);
