import {FORM_ERROR} from 'final-form';

export const ROOT = document.getElementById('root');

export const AUTHORIZATION_BASE_ROUTE = '/authorization';
export const PARTNERS_BASE_ROUTE = '/partners';
export const IMPORT_CONFIGS_BASE_ROUTE = '/import-configs';
export const ERROR_BASE_ROUTE = '/error';
export const ROUTES = {
    root: '/',
    login: `${AUTHORIZATION_BASE_ROUTE}/login`,
    partners: PARTNERS_BASE_ROUTE,
    partnersCreate: `${PARTNERS_BASE_ROUTE}/create`,
    partnersEdit: `${PARTNERS_BASE_ROUTE}/:id/edit`,
    importConfigs: IMPORT_CONFIGS_BASE_ROUTE,
    importConfigsCreate: `${IMPORT_CONFIGS_BASE_ROUTE}/create`,
    importConfigsEdit: `${IMPORT_CONFIGS_BASE_ROUTE}/:id/edit`,
    pageNotFound: `${ERROR_BASE_ROUTE}/page_not_found`,
    serverError: `${ERROR_BASE_ROUTE}/server_error`
};

export const HTTP_CODE_UNAUTHORIZED = 401;
export const HTTP_CODE_NOT_FOUND = 404;
export const HTTP_CODE_UNPROCESSABLE = 422;
export const SERVER_ERROR = 'SERVER_ERROR';

export const EMAIL_REGEXP =
    /^[a-zA-Z0-9_!.#$%&'*+\-/=?^`{|}~]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/;
export const INT_REGEXP = /^[0-9]*$/;
export const PIPE_DELIMITER = '|';

export const SORTING_ORDERS = {asc: 'asc', desc: 'desc'};

export const FORM_GLOBAL_ERROR = FORM_ERROR;
export const FORMS = {
    fileUpload: 'fileUpload',
    importConfig: 'importConfig',
    login: 'login',
    partner: 'partner',
    eligibilityImportConfig: 'eligibilityImportConfig',
    insuranceVerificationImportConfig: 'insuranceVerificationImportConfig',
    medicalClaimsImportConfig: 'medicalClaimsImportConfig',
    pharmacyClaimsImportConfig: 'pharmacyClaimsImportConfig',
    preCertImportConfig: 'preCertImportConfig',
    consultsImportConfig: 'consultsImportConfig',
    utilizationImportConfig: 'utilizationImportConfig',
    procedureImportConfig: 'procedureImportConfig'
};

export const TPA_FILE_TYPES = {
    eligibility: 'eligibility',
    insuranceVerification: 'insurance_verification',
    medicalClaims: 'medical_claims',
    preCert: 'pre_certification',
    consults: 'consults',
    pharmacyClaims: 'pharmacy_claims',
    caseManagement: 'case_management',
    utilization: 'utilization',
    procedure: 'procedure'
};

export const TPA_FILE_TYPE_OPTIONS = [
    {label: 'Eligibility', value: TPA_FILE_TYPES.eligibility},
    {label: 'Insurance Verification', value: TPA_FILE_TYPES.insuranceVerification},
    {label: 'Medical Claims', value: TPA_FILE_TYPES.medicalClaims},
    {label: 'Pre Certification', value: TPA_FILE_TYPES.preCert},
    {label: 'Consults', value: TPA_FILE_TYPES.consults},
    {label: 'Pharmacy Claims', value: TPA_FILE_TYPES.pharmacyClaims},
    {label: 'Case Management', value: TPA_FILE_TYPES.caseManagement},
    {label: 'Utilization', value: TPA_FILE_TYPES.utilization},
    {label: 'Procedure', value: TPA_FILE_TYPES.procedure}
];

export const TPA_DATA_PURPOSES = {
    eventAutomation: 'Event Automation',
    dataStorage: 'Data Storage'
};

export const TPA_DATA_PURPOSE_OPTIONS = {
    eligibility: [{label: 'Data Storage', value: TPA_DATA_PURPOSES.dataStorage}],
    insurance_verification: [
        {label: 'Data Storage', value: TPA_DATA_PURPOSES.dataStorage},
        {label: 'Event Automation', value: TPA_DATA_PURPOSES.eventAutomation}
    ],
    pre_certification: [
        {label: 'Data Storage', value: TPA_DATA_PURPOSES.dataStorage},
        {label: 'Event Automation', value: TPA_DATA_PURPOSES.eventAutomation}
    ],
    medical_claims: [
        {label: 'Data Storage', value: TPA_DATA_PURPOSES.dataStorage},
        {label: 'Event Automation', value: TPA_DATA_PURPOSES.eventAutomation}
    ],
    consults: [
        {label: 'Data Storage', value: TPA_DATA_PURPOSES.dataStorage},
        {label: 'Event Automation', value: TPA_DATA_PURPOSES.eventAutomation}
    ],
    pharmacy_claims: [{label: 'Data Storage', value: TPA_DATA_PURPOSES.dataStorage}],
    case_management: [
        {label: 'Data Storage', value: TPA_DATA_PURPOSES.dataStorage},
        {label: 'Event Automation', value: TPA_DATA_PURPOSES.eventAutomation}
    ],
    utilization: [
        {label: 'Data Storage', value: TPA_DATA_PURPOSES.dataStorage},
        {label: 'Event Automation', value: TPA_DATA_PURPOSES.eventAutomation}
    ],
    procedure: [
        {label: 'Data Storage', value: TPA_DATA_PURPOSES.dataStorage},
        {label: 'Event Automation', value: TPA_DATA_PURPOSES.eventAutomation}
    ],
};

export const EVENT_AUTOMATION_INFORMATION = {
    mainTopic: 'Automated insurance verification events ',
    body: "are triggered for TPA-backed members. When a member appears in an insurance verification feed, they will automatically receive one or more forms of outreach based on multiple criteria, like whether or not they're activated, time of day, etc.",
    conclusion:
        "This is intended to fully automate the process of proactively reaching out to members as they're seeking medical care.",
    fieldRequirements: 'Required'
};

export const DATA_STORAGE_INFORMATION = {
    mainTopic: 'Data storage ',
    body: 'refers to the use of files solely for the purposes of data analysis. When fields are mapped for any given file, when a data source transmits that file to HealthJoy, it is then transformed to our standards, then immediately stored in a database.',
    conclusion: "Using files for data storage has no impact on either members or HealthJoy HCC's.",
    fieldRequirements: 'Optional'
};

export const IMPORT_METHODS = {
    sftp: 'SFTP',
    manual: 'Manual Upload'
};

export const IMPORT_METHOD_OPTIONS = [
    {label: 'SFTP', value: IMPORT_METHODS.sftp},
    {label: 'Manual Upload', value: IMPORT_METHODS.manual}
];

export const ROWS_PER_RECORD_VALUES = {singleRowPerRecord: 'single', multipleRowsPerRecord: 'multiple'};

export const ROWS_PER_RECORD_OPTIONS = [
    {label: 'One row per-record', value: ROWS_PER_RECORD_VALUES.singleRowPerRecord},
    {label: 'Multiple rows per-record', value: ROWS_PER_RECORD_VALUES.multipleRowsPerRecord}
];

export const ROWS_PER_RECORD_INFORMATION = {
    mainTopic: '',
    body: 'Every data source handles and organizes their data a little differently. Typically, we assume that for each row of data we get about a member, there is a single corresponding event. However, there are sometimes cases where member activity can occupy multiple rows of a file that accounts for one event, and that one-to-one relationship between events and rows no longer applies.',
    conclusion:
        "In order to meet HealthJoy's internal data standards, or that one-to-one relationship, we need to transform this data. In order to do so, a common field between rows needs to be identified here. For example, if a member's name appears several rows consecutively with slightly different information on each row, we want to leverage some common identifier across multiple rows, like an Event ID, to combine those separate rows into one. This allows the data that HealthJoy ingests to be consistent across all partners."
};

export const HEADERLESS_TOOLTIP = {
    body: 'Do we expect the data source to provide header rows in their files?'
};

export const MARKETPLACE_TOOLTIP = {
    body: 'Will we identify people using a HealthJoy-generated ID?'
};

export const EXTERNALSFTP_TOOLTIP = {
    body: 'Do you want to enable External SFTP?'
};

export const JOIN_KEY_OPTIONS = [
    {
        tpa_file_type: TPA_FILE_TYPES.preCert,
        options: [
            {label: 'Event ID', value: 'event_id'},
            {label: 'Member ID', value: 'member_id'},
            {label: 'Family ID', value: 'family_id'},
            {label: 'Social Security Number', value: 'social_security_number'},
            {label: 'Patient First Name', value: 'patient_first_name'},
            {label: 'Patient Last Name', value: 'patient_last_name'},
            {label: 'Patient Date of Birth', value: 'patient_date_of_birth'},
            {label: 'Employer Name', value: 'employer_name'},
            {label: 'Group ID', value: 'group_id'},
            {label: 'Service Type', value: 'service_type'},
            {label: 'Auth Type', value: 'auth_type'},
            {label: 'Facility Name', value: 'facility_name'},
            {label: 'Facility NPI', value: 'facility_npi'},
            {label: 'Facility Tax ID', value: 'facility_tax_id'},
            {label: 'Provider Name', value: 'provider_name'},
            {label: 'Provider NPI', value: 'provider_npi'},
            {label: 'Provider Tax ID', value: 'provider_tax_id'}
        ]
    },
    {
        tpa_file_type: TPA_FILE_TYPES.insuranceVerification,
        data_purpose: TPA_DATA_PURPOSES.eventAutomation,
        options: [
            {label: 'Event ID', value: 'event_id'},
            {label: 'Date', value: 'verification_date'},
            {label: 'Member ID', value: 'medical_member_id'},
            {label: 'Social Security Number', value: 'ssn'},
            {label: 'Patient First Name', value: 'patient_fname'},
            {label: 'Patient Last Name', value: 'patient_lname'},
            {label: 'Patient Date of Birth', value: 'patient_dob'},
            {label: 'Employer Name', value: 'employer_name'},
            {label: 'Group ID', value: 'group_number'}
        ]
    },
    {
        tpa_file_type: TPA_FILE_TYPES.insuranceVerification,
        data_purpose: TPA_DATA_PURPOSES.dataStorage,
        options: [
            {label: 'Event ID', value: 'event_id'},
            {label: 'Date', value: 'verification_date'},
            {label: 'Member ID', value: 'medical_member_id'},
            {label: 'Employee System ID', value: 'system_employee_id'},
            {label: 'Social Security Number', value: 'ssn'},
            {label: 'Patient First Name', value: 'patient_fname'},
            {label: 'Patient Last Name', value: 'patient_lname'},
            {label: 'Patient Date of Birth', value: 'patient_dob'},
            {label: 'Employer Name', value: 'employer_name'},
            {label: 'Relationship', value: 'relationship'},
            {label: 'Group ID', value: 'group_number'},
            {label: 'Procedure Category', value: 'procedure_category'},
            {label: 'Contact Type', value: 'contact_type'},
            {label: 'Provider Name', value: 'provider_name'},
            {label: 'Provider NPI', value: 'provider_npi'},
            {label: 'Facility Name', value: 'facility_name'},
            {label: 'Facility NPI', value: 'npi'}
        ]
    }
];

export const HEALTHJOY_FIELDS_MAP = {tpaFileType: 'tpa_file_type', dataPurpose: 'data_purpose'};
export const ACCEPTABLE_TEXT_FORMATS = ['.csv', '.txt', '.xls', '.xlsx', '.gpg', '.pgp'];
export const MANUAL_FILE_MAX_FILE_COUNT = 1;

export const ENVS = {dev: 'dev', stage: 'staging', production: 'production'};
export const MULTI_ENV_DEV_PREFIX = window.location.hostname.match(/hj[0-9]{2}-dev/)?.[0] || '';
export const ENV = {
    [`${MULTI_ENV_DEV_PREFIX}-proactive-care.hj.localhost`]: ENVS.dev,
    [`${MULTI_ENV_DEV_PREFIX}-proactive-care.healthjoy.com`]: ENVS.dev,
    [`proactive-care.${MULTI_ENV_DEV_PREFIX}.hjoy.net`]: ENVS.dev,
    'dev-proactive-care.hj.localhost': ENVS.dev,
    'proactive-care.hj01-stage.hjoy.net': ENVS.stage,
    'proactive-care.healthjoy.com': ENVS.production
}[window.location.host];

export const SENTRY_DSN = 'https://1a2a14fee00b442f96fb51a9f4163081@o326634.ingest.sentry.io/4505591031726082';
