import {CLOSE_POPUP, FINISH_LOADING, OPEN_POPUP, START_LOADING, SET_DIRTY_FORMS} from '../actions/actionTypes';

// FYI: popup structure - {[id]: {isOpened, id, modalType, children}} (30.06.2020, Oleh);
export const initialState = {
    loader: {
        isLoader: false,
        loadingCount: 0
    },
    popup: {},
    dirtyForms: []
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case START_LOADING: {
            const {isLoader} = payload;
            const loadingCount = state.loader.loadingCount + 1;
            const loader = {loadingCount, isLoader};

            return {...state, loader};
        }

        case FINISH_LOADING: {
            const loadingCount = state.loader.loadingCount - 1;
            const isLoader = state.loader.isLoader && !!loadingCount; // FYI: checking for request without loader (22.06.20, Oleh)
            const loader = {loadingCount, isLoader};

            return {...state, loader};
        }

        case OPEN_POPUP: {
            const {id} = payload;
            const popup = {...state.popup, [id]: {...payload, isOpened: true}};

            return {...state, popup};
        }

        case CLOSE_POPUP: {
            const {id} = payload;
            const popup = {...state.popup, [id]: {}};

            return {...state, popup};
        }

        case SET_DIRTY_FORMS: {
            const {dirtyForms} = payload;

            return {...state, dirtyForms};
        }

        default: {
            return state;
        }
    }
};
