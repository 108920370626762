import React from 'react';
import NavigationItem from '../NavigationItem';
import items from './items';
import './index.scss';

const Navigation = () => {
    const getNavigationItem = item => <NavigationItem key={item.path} {...item} />;

    return <nav className='navigation'>{items.map(getNavigationItem)}</nav>;
};

export default Navigation;
