import {
    IMPORT_CONFIG_FIELDS_RECEIVED,
    IMPORT_CONFIGS_RECEIVED,
    IMPORT_CONFIG_DELETED,
    SET_IMPORT_CONFIG_SUCCESS_STATUS,
    SET_SHOW_MANUAL_FILE_UPLOAD
} from '../actions/actionTypes';

const initialState = {
    importConfigFields: [],
    importConfigList: [],
    partnersList: [],
    isSuccessfulSave: null,
    showFileManualUploadForConfigId: null
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case IMPORT_CONFIG_FIELDS_RECEIVED: {
            const {
                fieldsData: importConfigFields,
                partnersData: {partners}
            } = payload;

            return {...state, importConfigFields, partnersList: partners};
        }

        case IMPORT_CONFIGS_RECEIVED: {
            const {import_configs: importConfigs} = payload;

            return {...state, importConfigList: importConfigs};
        }

        case IMPORT_CONFIG_DELETED: {
            const {importConfigId} = payload;

            const updatedConfigs = state.importConfigList.filter(config => config.id !== importConfigId);

            return {...state, importConfigList: updatedConfigs};
        }

        case SET_IMPORT_CONFIG_SUCCESS_STATUS: {
            const {isSuccess} = payload;

            return {...state, isSuccessfulSave: isSuccess};
        }

        case SET_SHOW_MANUAL_FILE_UPLOAD: {
            const {configId} = payload;

            return {...state, showFileManualUploadForConfigId: configId};
        }

        default: {
            return state;
        }
    }
};
