import scrollIntoView from 'scroll-into-view';
import format from 'date-fns/format';
import {EMAIL_REGEXP, INT_REGEXP, PIPE_DELIMITER} from './constants';

export const equal = (val, other) => val === other;

export const pipe = (...funcs) => {
    const _pipe =
        (prevFunc, currFunc) =>
        (...arg) =>
            currFunc(prevFunc(...arg));

    return funcs.reduce(_pipe);
};

export const compose = (...funcs) => pipe(...funcs.reverse());

export const pass = val => val;

export const negate = val => !val;

export const noop = () => {};

export const negateFunc = func => pipe(func, negate);

export const isPrimitive = val => val !== Object(val);

export const isBoolean = val => equal(typeof val, 'boolean');

export const isFunction = val => equal(typeof val, 'function');

export const isNumber = val => equal(typeof val, 'number');

export const isError = val => val instanceof Error;

export const isFormData = val => val instanceof FormData;

export const isFile = val => val instanceof File;

export const isBlob = val => val instanceof Blob;

export const isEmpty = (obj, {canBeBoolean = false, canBeNumber = false} = {}) => {
    if (canBeBoolean && isBoolean(obj)) {
        return false;
    }
    if (canBeNumber && isNumber(obj)) {
        return false;
    }
    if (isPrimitive(obj)) {
        return !obj;
    }
    if (Array.isArray(obj)) {
        return !obj.length;
    }
    return isEmpty(Object.keys(obj));
};

export const partial =
    (func, ...params) =>
    (...args) =>
        func(...params, ...args);

export const getItemKeyValue = key => obj => obj[key];

export const getEqual = (value, key) => pipe(key ? getItemKeyValue(key) : pass, partial(equal, value));

export const formatDate = (date, dateFormat) => date && format(date, dateFormat);

export const toCapitalize = str => str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const trimIfString = value => (typeof value === 'string' ? value.trim() : value);

export const splitAlongPipeDelimiter = groupNumbers => groupNumbers && groupNumbers.split(PIPE_DELIMITER);

export const getRegisteredFieldsValues = (registeredFields, values, nestingLevel = 0) => {
    return Object.keys(registeredFields)
        .map(fieldName => fieldName.split('.')[nestingLevel])
        .reduce((acc, fieldName) => ({...acc, [fieldName]: values[fieldName]}), {});
};

export const getErrorFieldNames = (errors = {}) => {
    const getFieldNames = (errors, name = '') => {
        if (isPrimitive(errors)) {
            return name;
        }

        if (Array.isArray(errors)) {
            return errors.map((item, index) => item && getFieldNames(item, `${name}[${index}]`));
        }

        return Object.entries(errors).map(
            ([key, value]) => value && getFieldNames(value, name ? `${name}.${key}` : key)
        );
    };

    return getFieldNames(errors).flat(Infinity).filter(Boolean);
};

export const scrollToComponent = (component, time = 500) => scrollIntoView(component, {time});

export const validateEmail = email => (!EMAIL_REGEXP.test(email) ? 'Please enter a valid email' : undefined);

export const getErrorlessData = data => {
    if (Array.isArray(data)) {
        return data.filter(negateFunc(isError)).map(getErrorlessData);
    }
    if (isError(data)) {
        // FYI: due to removing of empty fields by apisauce we've decided that default value for fields which contains error - null (02.07.2021, Oleh)
        return null;
    }
    if ([isPrimitive, isFormData, isFile, isBlob].some(func => func(data))) {
        return data;
    }

    return Object.entries(data).reduce((acc, [key, val]) => ({...acc, [key]: getErrorlessData(val)}), {});
};

export const validateRequired = (value, isBooleanExpected = false, isNumberExpected = true) => {
    return isEmpty(getErrorlessData(value), {canBeBoolean: isBooleanExpected, canBeNumber: isNumberExpected}) ? 'Required' : undefined;
};

export const validateNotBlank = value => {
    const trimmedValue = value && typeof value === 'string' ? value.trim() : null;
    return validateRequired(trimmedValue);
};

export const validateHeaderlessEntries = (isHeaderless, values) => {
    if (isHeaderless && !validateNotBlank(values)) {
        const isNumericValue = splitAlongPipeDelimiter(values).every(value => {
            return !isNaN(value);
        });
        return !isNumericValue ? 'Enter a numeric value' : undefined;
    }

    return undefined;
};

export const validateJoinKey = (value, joinKey, field) => (field === joinKey ? validateNotBlank(value) : undefined);

export const validateUniqueEntries = values =>
    values && new Set(values).size !== values.length && 'Values must be unique.';

export const validateInt = (value, range = {}) => {
    const {from = -Infinity, to = Infinity} = range;
    const isInRange = value >= from && value <= to;

    const intValidationMessage = !INT_REGEXP.test(value) && 'Enter a numeric value';
    const rangeValidationMessage = !isInRange && `Enter value between ${from} and ${to}`;

    return intValidationMessage || rangeValidationMessage || undefined;
};

export const validateMaxLength = (max, value) => {
    return value && value.length > max ? `Must be ${max} characters or less` : undefined;
};

export const promisifyAsyncFunction =
    (func, resolve = res => res, reject = () => {}) =>
    (...params) =>
        func(...params)
            .then(resolve)
            .catch(reject);

export const normalizeText = value => value && value.trimStart();

export const getFileNameFromPath = path => path && path.split('/').pop();

export const arrayLengthEquals = (array, length) => array && array.length === length;

export const getFormattedDataMapping = dataMapping => {
    const {data_field: joinedImportConfigFields} = dataMapping;

    if (!joinedImportConfigFields) {
        return [];
    }

    const validatedJoinedImportConfigFields = joinedImportConfigFields.replace(/^\|+|\|?$/g, '');
    const splitImportConfigFields = splitAlongPipeDelimiter(validatedJoinedImportConfigFields);

    return splitImportConfigFields
        ? splitImportConfigFields.map(dataField => {
              return {...dataMapping, data_field: dataField.trim()};
          })
        : [{...dataMapping, data_field: ''}];
}; 
