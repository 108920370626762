import React from 'react';
import Link from '@frontend/ui-kit/Components/Link';
import PageHeading from '../../../Components/shared/PageHeading';
import PageLayout from '../../../Components/shared/PageLayout';
import PartnersList from '../../../Components/Partners/List';

const List = () => {
    const button = (
        <Link isButton href='/partners/create'>
            + Create Data Source
        </Link>
    );

    return (
        <React.Fragment>
            <PageHeading button={button}>Data Sources</PageHeading>
            <PageLayout>
                <PartnersList />
            </PageLayout>
        </React.Fragment>
    );
};

export default List;
