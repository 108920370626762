import {AUTHORIZE, UNAUTHORIZE} from '../actions/actionTypes';

const initialState = {
    isAuthorized: !!localStorage.getItem('accessToken'),
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    userName: localStorage.getItem('userName') || ''
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case AUTHORIZE: {
            const {accessToken, refreshToken, userName} = payload;

            return {
                ...state,
                isAuthorized: true,
                accessToken,
                refreshToken,
                userName: userName || state.userName
            };
        }

        case UNAUTHORIZE: {
            return {...state, isAuthorized: false, accessToken: null, refreshToken: null, userName: ''};
        }

        default: {
            return state;
        }
    }
};
