import React from 'react';
import PageHeading from '../../../Components/shared/PageHeading';
import PageLayout from '../../../Components/shared/PageLayout';
import ImportConfigForm from '../../../Components/ImportConfigs/Form';

const Create = () => {
    return (
        <React.Fragment>
            <PageHeading>Create Import Config</PageHeading>

            <PageLayout>
                <ImportConfigForm />
            </PageLayout>
        </React.Fragment>
    );
};

export default Create;
