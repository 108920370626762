import GlobalErrorMessage from '@frontend/ui-kit/Components/GlobalErrorMessage';
import Input from '@frontend/ui-kit/Components/Input';
import Heading from '@frontend/ui-kit/Components/Heading';
import React, {useState} from 'react';
import Label from '@frontend/ui-kit/Components/Label';
import Button from '@frontend/ui-kit/Components/Button';
import Column from '@frontend/ui-kit/Components/Column';
import Row from '@frontend/ui-kit/Components/Row';
import Icon from '@frontend/ui-kit/Components/Icon';
import PropTypes from 'prop-types';
import Section from './Section';
import {Field} from '../../shared/FormComponents';
import './index.scss';

const Footer = ({importConfigId, submitError}) => {
    const submitButtonText = importConfigId ? 'Update' : 'Create';
    const [internalNotesShowing, setInternalNotesShowing] = useState(true);
    const iconType = internalNotesShowing ? 'triangle-down' : 'triangle-top';
    const onInternalNotesIconClick = () => setInternalNotesShowing(internalNotesShowing => !internalNotesShowing);

    return (
        <React.Fragment>
            <Heading gradation={6} fontWeight='semibold' className='import-config-form-section-heading'>
                <div>
                    Internal notes
                    <Icon type={iconType} className='internal-notes-icon' onClick={onInternalNotesIconClick} />
                </div>
            </Heading>
            <Section offset={0} sm={12}>
                <Row>
                    <Column sm={12}>
                        {internalNotesShowing && (
                            <Label type='input'>
                                <Field name='internal_notes'>
                                    {props => (
                                        <Input
                                            {...props}
                                            placeholder='Enter notes
                                                here for your friends and colleagues...'
                                        />
                                    )}
                                </Field>
                            </Label>
                        )}
                    </Column>
                </Row>
            </Section>
            <Section displaySeparator={false} offset={0} sm={12}>
                {submitError && <GlobalErrorMessage>{submitError}</GlobalErrorMessage>}

                <div className='import-config-form-action-bar'>
                    <div className='config-form-button'>
                        <Button className='config-form-button' isSubmit>
                            {submitButtonText}
                        </Button>
                    </div>
                </div>
            </Section>
        </React.Fragment>
    );
};

Footer.propTypes = {
    importConfigId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
    submitError: PropTypes.string
};
export default React.memo(Footer);
