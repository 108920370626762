import React from 'react';
import PageHeading from '../../../Components/shared/PageHeading';
import PageLayout from '../../../Components/shared/PageLayout';
import PartnerForm from '../../../Components/Partners/Form';

const Create = () => {
    return (
        <React.Fragment>
            <PageHeading>Create Data Source</PageHeading>

            <PageLayout>
                <PartnerForm />
            </PageLayout>
        </React.Fragment>
    );
};

export default Create;
