import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import Input from '@frontend/ui-kit/Components/Input';
import Button from '@frontend/ui-kit/Components/Button';
import Label from '@frontend/ui-kit/Components/Label';
import Heading from '@frontend/ui-kit/Components/Heading';
import GlobalErrorMessage from '@frontend/ui-kit/Components/GlobalErrorMessage';
import {Form, Field} from '../../shared/FormComponents';
import PasswordInput from '../../shared/PasswordInput/index';
import {redirectTo} from '../../../actions/general';
import {login, setAuthorizationData} from '../../../actions/authorization';
import {validateEmail, validateRequired} from '../../../utils';
import {FORM_GLOBAL_ERROR, FORMS, ROUTES} from '../../../constants';
import './index.scss';

const validate = values => ({
    username: validateEmail(values.username),
    password: validateRequired(values.password)
});

const LoginForm = () => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({username: '', password: ''});
    const [authData, setAuthData] = useState(null);

    const onSubmit = useCallback(
        async values => {
            const {isSuccess, authData, submissionGlobalError} = await dispatch(
                login(values.username, values.password)
            );

            if (!isSuccess) {
                return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
            }

            setInitialValues(values);
            setAuthData(authData);
        },
        [dispatch]
    );

    const onSubmitSuccess = useCallback(async () => {
        const {access_token: accessToken, refresh_token: refreshToken, profile} = authData;

        const {first_name: firstName = '', last_name: lastName = ''} = profile;
        const userName = `${firstName} ${lastName}`;

        dispatch(setAuthorizationData(accessToken, refreshToken, userName));
        dispatch(redirectTo(ROUTES.root));
    }, [dispatch, authData]);

    return (
        <Form
            name={FORMS.login}
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
        >
            {({handleSubmit, submitError}) => (
                <form className='login-form' onSubmit={handleSubmit} noValidate>
                    <div>
                        <Heading className='login-form__title' gradation={3}>
                            HealthJoy Proactive Care
                        </Heading>
                    </div>
                    <div className='login-form-body'>
                        <Label label='Email' type='input'>
                            <Field name='username' type='email'>
                                {props => (
                                    <Input
                                        {...props}
                                        type='email'
                                        placeholder='Enter your email address...'
                                        icon='mail'
                                    />
                                )}
                            </Field>
                        </Label>

                        <Label label='Password' type='input'>
                            <PasswordInput name='password' placeholder='Enter your password...' />
                        </Label>

                        {submitError && <GlobalErrorMessage>{submitError}</GlobalErrorMessage>}
                        <div className='login-form-action-bar'>
                            <Button type='secondary' size='lg' isSubmit>
                                Login
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        </Form>
    );
};

export {LoginForm as TestableLoginForm};
export default LoginForm;
