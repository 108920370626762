import React from 'react';
import PropTypes from 'prop-types';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';

const PageLayout = ({children}) => <ContentSection>{children}</ContentSection>;

PageLayout.propTypes = {
    children: PropTypes.node
};

export default PageLayout;
