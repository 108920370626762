import React from 'react';
import PropTypes from 'prop-types';
import Popup from '@frontend/ui-kit/Components/Popup';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import ConnectedFileUploadForm from './Form/FileUploadForm';

const UploadFilePopup = ({importConfigId, isOpened, onClose, tpaPartnerName, dataPurpose, fileType}) => {
    return (
        <Popup modalType='simple' isOpened={isOpened} onClose={onClose}>
            <PopupContent
                title='Manual File Upload'
                subtitle={`${tpaPartnerName} | ${fileType} | ${dataPurpose}`}
                modalType
            >
                <ConnectedFileUploadForm
                    importConfigId={importConfigId}
                    onCancel={onClose}
                    dataSource={tpaPartnerName}
                />
            </PopupContent>
        </Popup>
    );
};

UploadFilePopup.propTypes = {
    dataPurpose: PropTypes.string,
    fileType: PropTypes.string,
    importConfigId: PropTypes.number,
    isOpened: PropTypes.bool,
    onClose: PropTypes.func,
    tpaPartnerName: PropTypes.string
};

export default UploadFilePopup;
