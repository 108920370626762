import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading from '@frontend/ui-kit/Components/Heading';
import './index.scss';

const PageHeading = ({children, button}) => {
    const headingLength = button ? 10 : 12;

    return (
        <Row>
            <Column sm={headingLength}>
                <Heading gradation={2} className='page-heading'>
                    {children}
                </Heading>
            </Column>

            {button && <Column sm={2}>{button}</Column>}
        </Row>
    );
};

PageHeading.propTypes = {
    children: PropTypes.string.isRequired,
    button: PropTypes.element
};

export default React.memo(PageHeading);
