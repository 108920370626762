import React from 'react';
import PageHeading from '../../../Components/shared/PageHeading';
import PageLayout from '../../../Components/shared/PageLayout';
import Form from '../../../Components/ImportConfigs/Form';

const Edit = () => {
    return (
        <React.Fragment>
            <PageHeading>Edit Import Config</PageHeading>

            <PageLayout>
                <Form />
            </PageLayout>
        </React.Fragment>
    );
};

export default Edit;
