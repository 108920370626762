import React from 'react';
import Layout from '../../../Components/Error/Layout';
import ServerErrorContent from '../../../Components/Error/ServerError';

const ServerError = () => {
    return (
        <Layout>
            <ServerErrorContent />
        </Layout>
    );
};

export default ServerError;
