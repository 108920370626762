import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Button from '@frontend/ui-kit/Components/Button';
import Column from '@frontend/ui-kit/Components/Column';
import GlobalErrorMessage from '@frontend/ui-kit/Components/GlobalErrorMessage';
import Input from '@frontend/ui-kit/Components/Input';
import Label from '@frontend/ui-kit/Components/Label';
import {Form, Field} from '../../shared/FormComponents';
import SftpPasswordInput from './SftpPasswordInput';
import {getEqual, validateRequired} from '../../../utils';
import {redirectTo} from '../../../actions/general';
import {initPartnerForm, requestPartners, savePartnerRequest} from '../../../actions/partners';
import {FORMS, FORM_GLOBAL_ERROR, ROUTES} from '../../../constants';
import './index.scss';

const validateCreatedPartner = values => ({
    name: validateRequired(values.name),
    sftp_username: validateRequired(values.sftp_username),
    sftp_password: validateRequired(values.sftp_password)
});

const validateEditedPartner = values => ({
    name: validateRequired(values.name),
    sftp_username: validateRequired(values.sftp_username)
});

const PartnerForm = () => {
    const dispatch = useDispatch();
    const {id: partnerId} = useParams();
    const [initialValues, setInitialValues] = useState({});
    const [isEdit, setIsEdit] = useState(!!partnerId);

    const onSubmit = useCallback(
        async values => {
            const {isSuccess, isEdit, submissionGlobalError} = await dispatch(
                savePartnerRequest(partnerId, {...values})
            );

            if (!isSuccess) {
                return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
            }

            setInitialValues(values);
            setIsEdit(isEdit);
        },
        [dispatch, partnerId]
    );

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(initPartnerForm(FORMS.partner, partnerId));

            setInitialValues(data);
        })();
    }, [dispatch, partnerId]);

    const onSubmitSuccess = useCallback(async () => {
        if (isEdit) {
            dispatch(redirectTo(ROUTES.partners));
            return;
        }

        const {
            data: {partners}
        } = await dispatch(requestPartners());
        const {id: newPartnerId} = partners.find(getEqual(initialValues.name, 'name'));

        dispatch(redirectTo(`${ROUTES.partners}/${newPartnerId}/edit?id=${newPartnerId}`));
    }, [dispatch, initialValues, isEdit]);

    const onClose = useCallback(() => dispatch(redirectTo(ROUTES.partners)), [dispatch]);
    const submitButtonText = partnerId ? 'Update' : 'Create';

    return (
        <div className='partner-form' data-testid='partner-form'>
            <Column smOffset={3} sm={6}>
                <Form
                    name={FORMS.partner}
                    initialValues={initialValues}
                    validate={values => (partnerId ? validateEditedPartner(values) : validateCreatedPartner(values))}
                    onSubmit={onSubmit}
                    onSubmitSuccess={onSubmitSuccess}
                >
                    {({handleSubmit, submitError}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Label label='Name' type='input'>
                                    <Field name='name'>{props => <Input {...props} />}</Field>
                                </Label>
                                <Label label='Alias' type='input'>
                                    <Field name='vendor_alias'>{props => <Input {...props} placeholder='Marketplace alias for data source'/>}</Field>
                                </Label>
                                <Label label='SFTP Username' type='input'>
                                    <Field name='sftp_username'>{props => <Input {...props} />}</Field>
                                </Label>
                                <Label label='SFTP Password' type='input'>
                                    <SftpPasswordInput name='sftp_password' isEdit={isEdit} />
                                </Label>
                                {isEdit && (
                                    <div name='sftp_host_port'>
                                        <Label label='SFTP Host' type='textarea'>
                                            <Field name='sftp_host'>{props => <Input {...props} disabled />}</Field>
                                        </Label>
                                        <Label label='SFTP Port' type='textarea'>
                                            <Field name='sftp_port'>{props => <Input {...props} disabled />}</Field>
                                        </Label>
                                    </div>
                                )}
                                {submitError && <GlobalErrorMessage>{submitError}</GlobalErrorMessage>}
                                <div className='partner-form-action-bar'>
                                    <div className='button-wrapper'>
                                        <Button className='partner-form-button' onClick={onClose} type='secondary'>
                                            Close
                                        </Button>
                                        <Button className='partner-form-button' isSubmit>
                                            {submitButtonText}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Form>
            </Column>
        </div>
    );
};

export {PartnerForm as TestablePartnerForm};
export default React.memo(PartnerForm);
