import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {reducer as form} from 'redux-form';
import shared, {initialState as sharedInitialState} from './shared';
import general from './general';
import authorization from './authorization';
import partners from './partners';
import importConfigs from './importConfigs';
import history from '../history';
import {UNAUTHORIZE} from '../actions/actionTypes';
import {equal} from '../utils';

const router = connectRouter(history);

const appReducer = combineReducers({
    router,
    form,
    shared,
    general,
    authorization,
    partners,
    importConfigs
});

export default (state, action) => {
    // FYI: hack for state refreshing on sign-out.
    // We don't refresh router because of it strange behaviour (it redirects us to previous route)
    // and loader, because of common state for authorized/unauthorized user
    if (equal(action.type, UNAUTHORIZE)) {
        const {router, shared} = state;
        const {loader} = shared;

        state = {router, shared: {...sharedInitialState, loader}}; // eslint-disable-line
    }

    return appReducer(state, action);
};
