export default [
    {
        title: 'Data Sources',
        path: 'partners'
    },
    {
        title: 'Import Configs',
        path: 'import-configs'
    }
];
