import React from 'react';
import PropTypes from 'prop-types';
import Popup from '@frontend/ui-kit/Components/Popup';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';

const ConfirmDeletePopup = ({isOpened, onClose, onDelete, text}) => {
    const actionBar = (
        <Button type='danger' onClick={onDelete}>
            Delete
        </Button>
    );

    return (
        <Popup isOpened={isOpened} onClose={onClose} modalType='simple'>
            <PopupContent title='Confirm' actionBar={actionBar}>
                <Text>{text}</Text>
            </PopupContent>
        </Popup>
    );
};

ConfirmDeletePopup.propTypes = {
    isOpened: PropTypes.bool,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    text: PropTypes.string
};

export default ConfirmDeletePopup;
