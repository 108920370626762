import {create, CancelToken} from 'apisauce';
import store from './store';
import {startLoading, finishLoading} from './actions/shared';
import {redirectTo} from './actions/general';
import {signOut, handleTokenRefresh} from './actions/authorization';
import {getAuthorizationStatus, getAccessToken, getRefreshToken} from './selectors/authorization';
import apiServices from './apiServices';
import {equal} from './utils';
import {ROUTES, HTTP_CODE_UNAUTHORIZED, SERVER_ERROR} from './constants';

const api = create({
    baseURL: '/api/v1',
    headers: {'Content-Type': 'application/json', Pragma: 'no-cache'}
});

const normalizeResponse = response => {
    const {ok, data} = response;

    response.isSuccess = ok;
    response.data = data || {};
};

const getBearerAccessToken = () => {
    const accessToken = getAccessToken(store.getState());

    return accessToken ? `Bearer ${accessToken}` : null;
};

const addAuthorizationHeader = request => {
    const bearerAccessToken = getBearerAccessToken();

    if (!bearerAccessToken || request.noRefresh) {
        return false;
    }

    request.headers = {...request.headers, Authorization: bearerAccessToken};
};

const sources = {};
const handleCancelableRequest = request => {
    const {url, isCancelable} = request;

    if (!isCancelable) {
        return false;
    }

    if (sources[url]) {
        sources[url].cancel();
    }

    sources[url] = CancelToken.source();
    request.cancelToken = sources[url].token;
};

const ensureTokenFreshness = async response => {
    const config = response.config ?? {};
    if (config.noRefresh) {
        return false;
    }

    const isAuthorized = getAuthorizationStatus(store.getState());
    const isAuthorizationError = equal(response.status, HTTP_CODE_UNAUTHORIZED);
    const bearerAccessToken = getBearerAccessToken();

    if (!isAuthorized || !isAuthorizationError) {
        return false;
    }

    if (config.fresh) {
        // FYI: it handles wrong behavior of auth service if we get 401 more than once (21.07.2020, Oleh);
        store.dispatch(signOut());
        return false;
    }

    if (equal(config.headers.Authorization, bearerAccessToken)) {
        const token = getRefreshToken(store.getState());
        const {isSuccess: isRefreshSuccess, data: tokenData} = await apiServices.refreshToken({token});

        store.dispatch(handleTokenRefresh(isRefreshSuccess, tokenData));

        if (!isRefreshSuccess) {
            store.dispatch(signOut());
            return false;
        }
    }

    const {method, baseURL, url: fullUrl, ...restConfig} = config;
    const url = fullUrl.replace(new RegExp(`^${baseURL}`), '');

    const newResponse = await api[method](url, null, {...restConfig, fresh: true});

    Object.assign(response, newResponse);
};

const handleServerError = ({status, problem}) => {
    const isServerError = equal(SERVER_ERROR, problem);

    if (!isServerError) {
        return false;
    }

    store.dispatch(redirectTo(`${ROUTES.serverError}/${status}`));
};

const showLoader = ({isLoader = true}) => store.dispatch(startLoading(isLoader));
const hideLoader = () => store.dispatch(finishLoading());

api.addRequestTransform(showLoader);
api.addRequestTransform(addAuthorizationHeader);
api.addRequestTransform(handleCancelableRequest);

api.addAsyncResponseTransform(ensureTokenFreshness);
api.addResponseTransform(normalizeResponse);
api.addResponseTransform(hideLoader);

api.addMonitor(handleServerError);

export default api;
