import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Label from '@frontend/ui-kit/Components/Label';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Icon from '@frontend/ui-kit/Components/Icon';
import Text from '@frontend/ui-kit/Components/Text';
import {Field} from '../../shared/FormComponents';
import {validateRequired, validateInt} from '../../../utils';
import {EXTERNALSFTP_TOOLTIP} from '../../../constants';
import './index.scss';

const ExternalSftp = props => {
    const [isExternalSftp, setIsExternalSftp] = useState();

    useEffect(() => {
        setIsExternalSftp(props.isExternalSftp);
    }, [props.isExternalSftp]);

    const onExternalSftpChange = event => {
        event.persist();
        setIsExternalSftp(!isExternalSftp);
    };

    const externalSftpTooltipText = <Text className='information-paragraph'>{EXTERNALSFTP_TOOLTIP.body}</Text>;

    const externalSftpTooltip = (
        <div>
            External SFTP
            <Tooltip isClickable content={externalSftpTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    const composeValidators =
        (...validators) =>
        value =>
            validators.reduce((error, validator) => error || validator(value), undefined);

    return (
        <div className='external-sftp'>
            <Column sm={12} className='checkbox'>
                <Label label={externalSftpTooltip}>
                    <Field
                        name='is_external_sftp'
                        type='Checkbox'
                        checked={isExternalSftp}
                        onChange={onExternalSftpChange}
                    >
                        {props => <Checkbox {...props} />}
                    </Field>
                </Label>
            </Column>

            {isExternalSftp && (
                <React.Fragment>
                    <Column sm={6}>
                        <Label label='Credentials Link' type='input'>
                            <Field name='external_sftp.credentials_link' validate={composeValidators(validateRequired)}>
                                {props => <Input {...props} />}
                            </Field>
                        </Label>
                    </Column>
                    <Column sm={6}>
                        <Label label='Directory Path' type='input'>
                            <Field name='external_sftp.directory_path'>{props => <Input {...props} />}</Field>
                        </Label>
                    </Column>
                    <Column sm={6}>
                        <Label label='Filename Pattern' type='input'>
                            <Field name='external_sftp.filename_pattern' validate={composeValidators(validateRequired)}>
                                {props => <Input {...props} />}
                            </Field>
                        </Label>
                    </Column>
                    <Column sm={6}>
                        <Label label='Sync Frequency In Hours' type='input'>
                            <Field
                                name='external_sftp.sync_frequency_in_hours'
                                validate={composeValidators(validateRequired, validateInt)}
                            >
                                {props => <Input {...props} />}
                            </Field>
                        </Label>
                    </Column>
                    <Field name='external_sftp.last_sync'>{props => <Input {...props} type='hidden' />}</Field>
                    <Field name='external_sftp_id'>{props => <Input {...props} type='hidden' />}</Field>
                </React.Fragment>
            )}
        </div>
    );
};

ExternalSftp.propTypes = {
    isExternalSftp: PropTypes.bool
};

ExternalSftp.defaultProps = {
    isExternalSftp: false
};

export {ExternalSftp as TestableExternalSftp};
export default React.memo(ExternalSftp);
