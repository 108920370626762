import React from 'react';
import PropTypes from 'prop-types';
import {Field as SimpleField} from 'react-final-form';
import useForm from '../../../../hooks/useForm';

const Field = ({children, onChange, type, ...restProps}) => {
    const form = useForm();

    return (
        <SimpleField type={type} {...restProps}>
            {props => {
                // eslint-disable-next-line react/prop-types
                const {input, meta, ...restProps} = props;
                // eslint-disable-next-line react/prop-types
                const {checked, ...restInput} = input;
                const {
                    valid: isValid,
                    error: errorMessage,
                    submitError: submissionErrorMessage,
                    touched: isTouched
                } = meta;

                return children({
                    ...restInput,
                    ...restProps,
                    isValid,
                    errorMessage: errorMessage || submissionErrorMessage,
                    isTouched,
                    onChange: async event => {
                        if (onChange) {
                            await onChange(event, form);
                        }

                        if (!event?.defaultPrevented) {
                            // eslint-disable-next-line react/prop-types
                            input.onChange(event);
                        }
                    }
                });
            }}
        </SimpleField>
    );
};

Field.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    allowNull: PropTypes.bool,
    type: PropTypes.string,
    format: PropTypes.func,
    parse: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    meta: PropTypes.shape({
        valid: PropTypes.bool,
        error: PropTypes.string,
        submitError: PropTypes.string,
        touched: PropTypes.bool
    })
};

export default Field;
