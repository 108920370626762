import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import UserInfo from '../UserInfo';
import Navigation from '../Navigation';
import './index.scss';

const Header = () => {
    return (
        <Row middle='xs' className='header'>
            <Column sm={10} className='header-navigation'>
                <Navigation />
            </Column>

            <Column sm={2} className='header-user'>
                <UserInfo />
            </Column>
        </Row>
    );
};

export default Header;
