import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import Heading from '@frontend/ui-kit/Components/Heading';
import Icon from '@frontend/ui-kit/Components/Icon';
import Input from '@frontend/ui-kit/Components/Input';
import Label from '@frontend/ui-kit/Components/Label';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import Row from '@frontend/ui-kit/Components/Row';
import Select from '@frontend/ui-kit/Components/Select';
import Text from '@frontend/ui-kit/Components/Text';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Footer from './Footer';
import {Form, Field} from '../../shared/FormComponents';
import Section from './Section';
import ExternalSftp from '../ExternalSftp';
import Encryption from './Encryption';
import {
    getEqual,
    getFormattedDataMapping,
    validateJoinKey,
    validateMaxLength,
    validateNotBlank,
    validateRequired,
    validateHeaderlessEntries
} from '../../../utils';
import {redirectTo} from '../../../actions/general';
import {initImportConfigForm, saveImportConfigForm, saveExternalSftp} from '../../../actions/importConfigs';
import {
    FORMS,
    FORM_GLOBAL_ERROR,
    ROUTES,
    IMPORT_METHOD_OPTIONS,
    ROWS_PER_RECORD_OPTIONS,
    ROWS_PER_RECORD_INFORMATION,
    HEALTHJOY_FIELDS_MAP,
    JOIN_KEY_OPTIONS,
    ROWS_PER_RECORD_VALUES,
    TPA_DATA_PURPOSES,
    TPA_FILE_TYPES,
    HEADERLESS_TOOLTIP,
    MARKETPLACE_TOOLTIP
} from '../../../constants';
import './index.scss';

const validate = values => ({
    tpa_partner_id: validateRequired(values.tpa_partner_id),
    import_method: validateRequired(values.import_method),
    event_id:
        validateJoinKey(values.event_id, values.join_key, 'event_id') ||
        validateHeaderlessEntries(values.is_headerless, values.event_id),
    date: validateHeaderlessEntries(values.is_headerless, values.date),
    member_id: 
        validateHeaderlessEntries(values.is_headerless, values.member_id) ||
        validateNotBlank(values.member_id),
    family_id:
        validateJoinKey(values.family_id, values.join_key, 'family_id') ||
        validateHeaderlessEntries(values.is_headerless, values.family_id),
    social_security_number:
        validateJoinKey(values.social_security_number, values.join_key, 'social_security_number') ||
        validateHeaderlessEntries(values.is_headerless, values.social_security_number),
    patient_first_name:
        validateJoinKey(values.patient_first_name, values.join_key, 'patient_first_name') ||
        validateHeaderlessEntries(values.is_headerless, values.patient_first_name) ||
        validateNotBlank(values.patient_first_name),
    patient_last_name:
        validateJoinKey(values.patient_last_name, values.join_key, 'patient_last_name') ||
        validateHeaderlessEntries(values.is_headerless, values.patient_last_name) ||
        validateNotBlank(values.patient_last_name),
    patient_date_of_birth:
        validateJoinKey(values.patient_date_of_birth, values.join_key, 'patient_date_of_birth') ||
        validateHeaderlessEntries(values.is_headerless, values.patient_date_of_birth),
    employer_name:
        validateJoinKey(values.employer_name, values.join_key, 'employer_name') ||
        validateHeaderlessEntries(values.is_headerless, values.employer_name),
    group_id:
        validateJoinKey(values.group_id, values.join_key, 'group_id') ||
        validateHeaderlessEntries(values.is_headerless, values.group_id),
    service_type:
        validateJoinKey(values.service_type, values.join_key, 'service_type') ||
        validateHeaderlessEntries(values.is_headerless, values.service_type),
    auth_type:
        validateJoinKey(values.auth_type, values.join_key, 'auth_type') ||
        validateHeaderlessEntries(values.is_headerless, values.auth_type),
    decision: validateHeaderlessEntries(values.is_headerless, values.decision),
    icd10: validateHeaderlessEntries(values.is_headerless, values.icd10),
    cpt: validateHeaderlessEntries(values.is_headerless, values.cpt),
    facility_name:
        validateJoinKey(values.facility_name, values.join_key, 'facility_name') ||
        validateHeaderlessEntries(values.is_headerless, values.facility_name),
    facility_npi:
        validateJoinKey(values.facility_npi, values.join_key, 'facility_npi') ||
        validateHeaderlessEntries(values.is_headerless, values.facility_npi),
    facility_tax_id:
        validateJoinKey(values.facility_tax_id, values.join_key, 'facility_tax_id') ||
        validateHeaderlessEntries(values.is_headerless, values.facility_tax_id),
    provider_name:
        validateJoinKey(values.provider_name, values.join_key, 'provider_name') ||
        validateHeaderlessEntries(values.is_headerless, values.provider_name),
    provider_npi:
        validateJoinKey(values.provider_npi, values.join_key, 'provider_npi') ||
        validateHeaderlessEntries(values.is_headerless, values.provider_npi),
    provider_tax_id:
        validateJoinKey(values.provider_tax_id, values.join_key, 'provider_tax_id') ||
        validateHeaderlessEntries(values.is_headerless, values.provider_tax_id),
    internal_notes: validateMaxLength(300, values.internal_notes)
});

const PreCertificationEventAutomationForm = ({partnerOptions}) => {
    const dispatch = useDispatch();
    const {id: configId} = useParams();
    const [initialValues, setInitialValues] = useState({});
    const [importConfigId, setImportConfigId] = useState(configId);
    const [importMethod, setImportMethod] = useState();
    const [isEdit, setIsEdit] = useState(!!importConfigId);
    const [hasMultipleRowsPerRecord, setHasMultipleRowsPerRecord] = useState(null);
    const [isHeaderless, setIsHeaderless] = useState(false);
    const [isMarketplaceIdentifiable, setIsMarketplaceIdentifiable] = useState(false);
    const [isEncrypted, setIsEncrypted] = useState();
    const [isExternalSftp, setIsExternalSftp] = useState();

    const onSubmit = useCallback(
        async values => {
            const {
                id: configId,
                tpa_partner: tpaPartner,
                tpa_file_type: tpaFileType,
                partnerOptions: partners,
                data_purpose: dataPurpose,
                data_mappings: dataMappings,
                tpa_partner_id: tpaPartnerId,
                import_method: importMethod,
                has_multiple_rows_per_record: rowsPerRecord,
                join_key: joinKey,
                internal_notes: internalNotes,
                is_headerless: isHeaderless,
                is_marketplace_identifiable: isMarketplaceIdentifiable,
                is_encrypted_file: isEncrypted,
                vault_path: vaultPath,
                is_external_sftp: isExternalSftp,
                external_sftp_id: externalSftpId,
                external_sftp: {
                    credentials_link: credentialsLink,
                    directory_path: directoryPath,
                    filename_pattern: filenamePattern,
                    sync_frequency_in_hours: syncFrequencyInHours,
                    last_sync: lastSync
                } = {},
                ...dataMappingsFormValues
            } = values;

            const formDataMappings = Object.entries(dataMappingsFormValues).map(([fieldName, fieldValue]) => ({
                healthjoy_field: fieldName,
                data_field: fieldValue
            }));
            const formattedDataMappings = formDataMappings.map(getFormattedDataMapping).flat();

            const filterOutEmpties = mapping => {
                const {data_field: fieldValue} = mapping;

                return !!fieldValue;
            };

            const validDataMappings = formattedDataMappings.filter(filterOutEmpties);
            const importConfigRequestParams = {
                tpa_file_type: TPA_FILE_TYPES.preCert,
                data_purpose: TPA_DATA_PURPOSES.eventAutomation,
                tpa_partner_id: tpaPartnerId,
                import_method: importMethod,
                join_key: rowsPerRecord === ROWS_PER_RECORD_VALUES.multipleRowsPerRecord ? joinKey : null,
                data_mappings: validDataMappings,
                is_headerless: isHeaderless || false,
                is_marketplace_identifiable: isMarketplaceIdentifiable || false,
                is_encrypted_file: isEncrypted,
                vault_path: vaultPath || null,
                is_external_sftp: importMethod === 'Manual Upload' ? false : isExternalSftp,
                external_sftp_id: externalSftpId || null,
                internal_notes: internalNotes || null
            };

            if (isExternalSftp && importMethod === 'SFTP') {
                const todaysDate = new Date().toISOString();

                const externalSftpRequestParams = {
                    credentials_link: credentialsLink,
                    directory_path: directoryPath || '',
                    filename_pattern: filenamePattern,
                    sync_frequency_in_hours: syncFrequencyInHours,
                    last_sync: lastSync || todaysDate
                };

                const {data, isSuccess, submissionGlobalError} = await dispatch(
                    saveExternalSftp(externalSftpId, {...externalSftpRequestParams})
                );

                if (!isSuccess) {
                    return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
                }

                importConfigRequestParams.external_sftp_id = data.id;
            }

            const {data, isSuccess, isEdit, submissionGlobalError} = await dispatch(
                saveImportConfigForm(configId, {...importConfigRequestParams})
            );
            const {id: importConfigId} = data;

            if (!isSuccess) {
                return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
            }

            setInitialValues(values);
            setIsEdit(isEdit);
            setImportConfigId(importConfigId);
            setIsHeaderless(isHeaderless);
            setIsMarketplaceIdentifiable(isMarketplaceIdentifiable);
        },
        [dispatch]
    );

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(initImportConfigForm(importConfigId));
            const {
                join_key: joinKey,
                is_headerless: isHeaderless,
                is_marketplace_identifiable: isMarketplaceIdentifiable,
                is_encrypted_file: isEncrypted,
                is_external_sftp: isExternalSftp,
                import_method: importMethod
            } = data;

            setInitialValues(data);
            setHasMultipleRowsPerRecord(!!joinKey);
            setIsHeaderless(isHeaderless);
            setIsMarketplaceIdentifiable(isMarketplaceIdentifiable);
            setIsEncrypted(isEncrypted);
            setIsExternalSftp(isExternalSftp);
            setImportMethod(importMethod);
        })();
    }, [dispatch, importConfigId]);

    const onSubmitSuccess = useCallback(async () => {
        if (!isEdit) {
            dispatch(redirectTo(`${ROUTES.importConfigs}/${importConfigId}/edit?id=${importConfigId}`));
        }
    }, [dispatch, importConfigId, isEdit]);

    const onRowsPerRecordChange = (event, form) => {
        event.persist();
        const {value} = event.target;
        const isMultipleRowsPerRecord = value === ROWS_PER_RECORD_VALUES.multipleRowsPerRecord;
        setHasMultipleRowsPerRecord(isMultipleRowsPerRecord);
        if (!isMultipleRowsPerRecord) {
            form.mutators.resetJoinKey();
        }
    };

    const onImportMethodChange = value => {
        setImportMethod(value);

        if (importMethod === 'Manual Upload') {
            setIsExternalSftp(false);
        }
    };

    const captionText = hasMultipleRowsPerRecord ? 'Combined in Records' : '';

    const rowsPerRecordText = (
        <Text>
            <Text className='informational-paragraph'>{ROWS_PER_RECORD_INFORMATION.body}</Text>
            {ROWS_PER_RECORD_INFORMATION.conclusion}
        </Text>
    );

    const rowsPerRecordTooltip = (
        <div>
            Rows Per Record
            <Tooltip isClickable content={rowsPerRecordText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    const onHeaderlessChange = (event, form) => {
        event.persist();
        setIsHeaderless(!isHeaderless);
        form.change('is_headerless', !isHeaderless);
    };

    const headerlessTooltipText = <Text className='information-paragraph'>{HEADERLESS_TOOLTIP.body}</Text>;

    const headerlessTooltip = (
        <div>
            Headerless
            <Tooltip isClickable content={headerlessTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    const onMarketplaceIdentifiableChange = (event, form) => {
        event.persist();
        setIsMarketplaceIdentifiable(isMarketplaceIdentifiable => !isMarketplaceIdentifiable);
        form.change('is_marketplace_identifiable', !isMarketplaceIdentifiable);
    };

    const marketplaceTooltipText = <Text className='information-paragraph'>{MARKETPLACE_TOOLTIP.body}</Text>;

    const marketplaceTooltip = (
        <div>
            HealthJoy-generated Identifier
            <Tooltip isClickable content={marketplaceTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    const {options: joinKeyOptions} = hasMultipleRowsPerRecord
        ? JOIN_KEY_OPTIONS.find(getEqual(TPA_FILE_TYPES.preCert, HEALTHJOY_FIELDS_MAP.tpaFileType))
        : [];

    return (
        <div className='import-config-form'>
            <Column offset={0} sm={12}>
                <Form
                    name={FORMS.preCertImportConfig}
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={onSubmit}
                    onSubmitSuccess={onSubmitSuccess}
                    mutators={{
                        resetJoinKey: (args, state, utils) => {
                            utils.changeValue(state, 'join_key', () => null);
                        }
                    }}
                >
                    {({handleSubmit, submitError}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Section header='Data Source' offset={0} sm={12}>
                                    <Row className='import-config-form-upper-margin'>
                                        <Column sm={4}>
                                            <Label label='Source' type='select'>
                                                <Field name='tpa_partner_id'>
                                                    {props => <Select {...props} options={partnerOptions} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Import Method' type='select'>
                                                <Field name='import_method' onChange={onImportMethodChange}>
                                                    {props => <Select {...props} options={IMPORT_METHOD_OPTIONS} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Encryption isEncrypted={isEncrypted} />
                                        {importMethod === 'SFTP' && <ExternalSftp isExternalSftp={isExternalSftp} />}
                                    </Row>
                                </Section>
                                <Section header='Data Mapping' offset={0} sm={12} displaySeparator={false}>
                                    <Row className='import-config-form-upper-margin'>
                                        <Column sm={4}>
                                            <Label label={rowsPerRecordTooltip} type='select'>
                                                <Field
                                                    name='has_multiple_rows_per_record'
                                                    onChange={onRowsPerRecordChange}
                                                >
                                                    {props => (
                                                        <RadioGroup {...props} options={ROWS_PER_RECORD_OPTIONS} />
                                                    )}
                                                </Field>
                                            </Label>
                                        </Column>
                                        {hasMultipleRowsPerRecord && (
                                            <Column sm={4}>
                                                <Label label='Key To Join Records' type='select'>
                                                    <Field name='join_key'>
                                                        {props => <Select {...props} options={joinKeyOptions} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        )}
                                        <Column sm={12}>
                                            <Label label={headerlessTooltip} type='checkbox'>
                                                <Field name='is_headerless' onChange={onHeaderlessChange}>
                                                    {props => <Checkbox {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={12}>
                                            <Label label={marketplaceTooltip} type='checkbox'>
                                                <Field name='is_marketplace_identifiable' onChange={onMarketplaceIdentifiableChange}>
                                                    {props => <Checkbox {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                    </Row>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Event information
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Event ID' type='input'>
                                                    <Field name='event_id'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Date' type='input' caption={captionText}>
                                                    <Field name='date'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Member information
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Member ID' type='input'>
                                                    <Field name='member_id'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Family ID' type='input'>
                                                    <Field name='family_id'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Social Security Number' type='input'>
                                                    <Field name='social_security_number'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Patient First Name' type='input'>
                                                    <Field name='patient_first_name'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Patient Last Name' type='input'>
                                                    <Field name='patient_last_name'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Patient Date of Birth' type='input'>
                                                    <Field name='patient_date_of_birth'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Employer Name' type='input'>
                                                    <Field name='employer_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Group ID' type='input'>
                                                    <Field name='group_id'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Procedure & service{' '}
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Service Type' type='input'>
                                                    <Field name='service_type'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Auth Type' type='input'>
                                                    <Field name='auth_type'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Decision' type='input' caption={captionText}>
                                                    <Field name='decision'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='ICD10' type='input' caption={captionText}>
                                                    <Field name='icd10'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='CPT' type='input' caption={captionText}>
                                                    <Field name='cpt'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Facility & provider
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Facility Name' type='input'>
                                                    <Field name='facility_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Facility NPI' type='input'>
                                                    <Field name='facility_npi'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Facility Tax ID' type='input'>
                                                    <Field name='facility_tax_id'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Provider Name' type='input'>
                                                    <Field name='provider_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Provider NPI' type='input'>
                                                    <Field name='provider_npi'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Provider Tax ID' type='input'>
                                                    <Field name='provider_tax_id'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                </Section>
                                <Footer importConfigId={importConfigId} submitError={submitError} />
                            </form>
                        );
                    }}
                </Form>
            </Column>
        </div>
    );
};

PreCertificationEventAutomationForm.propTypes = {
    partnerOptions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number,
            sftp_port: PropTypes.number,
            sftp_host: PropTypes.string,
            sftp_username: PropTypes.string,
            tpa_name_slug: PropTypes.string,
        })
    )
};

export {PreCertificationEventAutomationForm as TestablePreCertificationEventAutomationForm};
export default React.memo(PreCertificationEventAutomationForm);
