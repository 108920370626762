import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import Link from '@frontend/ui-kit/Components/Link';
import Text from '@frontend/ui-kit/Components/Text';
import {ROUTES} from '../../../constants';
import hjLogo from '../../../static/images/hjLogoPurple.svg';

const ServerError = () => {
    const match = useRouteMatch();
    const {errorCode} = match.params;

    return (
        <div className='error-page server-error'>
            <img className='error-page__logo' src={hjLogo} alt='' />

            <Text fontWeight='bold' className='error-page__header'>
                {errorCode}
            </Text>

            <Text className='error-page__subheader'>Server Error</Text>

            <Text className='error-page__description'>
                This service is currently unavailable. Our team is working hard to up it back as soon as possible.
            </Text>

            <Link isButton type='secondary' href={ROUTES.root}>
                Go to homepage
            </Link>
        </div>
    );
};

export default ServerError;
