import {PARTNERS_RECEIVED, PARTNER_DELETED} from './actionTypes';
import apiServices from '../apiServices';

const partnersReceived = data => ({type: PARTNERS_RECEIVED, ...data});
const partnerDeleted = partnerId => ({type: PARTNER_DELETED, partnerId});

export const requestPartners = () => async dispatch => {
    const {data, isSuccess} = await apiServices.getPartners();

    if (isSuccess) {
        dispatch(partnersReceived(data));
    }
    return {data};
};

export const deletePartner = partnerId => async dispatch => {
    const {isSuccess} = await apiServices.deletePartner(partnerId);

    if (isSuccess) {
        dispatch(partnerDeleted(partnerId));
    }
};

export const initPartnerForm = (form, partnerId) => async () => {
    const {data, isSuccess} = partnerId ? await apiServices.getPartner(partnerId) : {data: {}};

    return {data, isSuccess};
};

export const savePartnerRequest = (partnerId, values) => async () => {
    const {data_types: dataTypes, id, tpa_name_slug: tpaNameSlug, ...partnerPayload} = values;
    const {data: partnerData, isSuccess} = partnerId
        ? await apiServices.updatePartner(partnerId, partnerPayload)
        : await apiServices.createPartner(partnerPayload);

    if (!isSuccess) {
        const {message} = partnerData;

        return {submissionGlobalError: message};
    }

    return {partnerData, isSuccess, isEdit: !!partnerId};
};
