import React from 'react';
import Layout from '../../../Components/Authorization/Layout';
import LoginForm from '../../../Components/Authorization/LoginForm';

const Login = () => {
    return (
        <Layout>
            <LoginForm />
        </Layout>
    );
};

export {Login as TestableLogin};
export default React.memo(Login);
