import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import '@frontend/ui-kit/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ROOT, SENTRY_DSN, ENV} from './constants';
import './index.scss';

if (SENTRY_DSN)
    console.log("Initializing Sentry in this env: " + ENV)
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 0.0,
        environment: ENV
});

ReactDOM.render(<App />, ROOT);

serviceWorker.unregister();
