import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Icon from '@frontend/ui-kit/Components/Icon';
import DropdownWrapper from '@frontend/ui-kit/Components/DropdownWrapper';
import {signOut} from '../../../actions/authorization';
import {getUserName} from '../../../selectors/authorization';
import './index.scss';

const UserInfo = () => {
    const dispatch = useDispatch();
    const userName = useSelector(getUserName);
    const dropdownWrapperProps = {
        className: 'user-avatar',
        aligning: 'right',
        renderDropdownInitiator: () => <Icon type='avatar' />
    };

    return (
        <div className='user-info'>
            <div className='user-info-profile'>
                <div>
                    <div className='user-info-profile__name'>{userName}</div>
                </div>

                <DropdownWrapper {...dropdownWrapperProps}>
                    {({onClose}) => {
                        const onSighOutClick = () => {
                            dispatch(signOut());
                            onClose();
                        };

                        return (
                            <div className='user-actions-list'>
                                <div className='user-actions-list__item' onClick={onSighOutClick}>
                                    <Icon type='sign-out' /> Sign out
                                </div>
                            </div>
                        );
                    }}
                </DropdownWrapper>
            </div>
        </div>
    );
};

export default UserInfo;
