import React, {useState} from 'react';
import PropTypes from 'prop-types';
import PasswordInput from '../../shared/PasswordInput';
import {Field} from '../../shared/FormComponents';
import './index.scss';

const SftpPasswordInput = ({name, isEdit, ...restProps}) => {
    const [isPasswordPrivate, setIsPasswordPrivate] = useState(true);
    const onToggleIsPasswordPrivate = () => setIsPasswordPrivate(isPasswordPrivate => !isPasswordPrivate);

    return (
        <Field className='password-input'>
            {props => {
                const displayCurrentPassword = isEdit && isPasswordPrivate;

                return displayCurrentPassword ? (
                    <span className='update-password' onClick={onToggleIsPasswordPrivate} {...props}>
                        Click here to update SFTP Password
                    </span>
                ) : (
                    <PasswordInput {...restProps} name={name} />
                );
            }}
        </Field>
    );
};

SftpPasswordInput.propTypes = {
    isEdit: PropTypes.bool,
    name: PropTypes.string.isRequired
};

export {SftpPasswordInput as TestableSftpPasswordInput};
export default SftpPasswordInput;
