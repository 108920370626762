import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Layout = ({children}) => <div className='error-layout'>{children}</div>;

Layout.propTypes = {
    children: PropTypes.node
};

export default React.memo(Layout);
