import api from '../api';
import {
    TOKEN_ENDPOINT,
    TOKEN_REFRESH_ENDPOINT,
    PARTNERS_ENDPOINT,
    IMPORT_CONFIG_FIELDS_ENDPOINT,
    IMPORT_CONFIGS_ENDPOINT,
    EXTERNAL_SFTPS_ENDPOINT,
    MANUAL_FILES_ENDPOINT,
    HJ_CDN_BASE_URL
} from './constants';

class ApiServices {
    login = async ({username, password}) => api.post(TOKEN_ENDPOINT, {username, password});

    /* TODO: these delete calls both return 405 errors from the BE. 'Signing out' currently consists of removing tokens from local storage (08.02.22, Laura)s */
    signOut = async ({accessToken, refreshToken}) =>
        Promise.all([
            api.delete(TOKEN_ENDPOINT, null, {params: {token: accessToken}, noRefresh: true}),
            api.delete(TOKEN_REFRESH_ENDPOINT, null, {params: {token: refreshToken}, noRefresh: true})
        ]);

    refreshToken = async ({token}) => api.post(TOKEN_REFRESH_ENDPOINT, {}, {params: {token}, noRefresh: true});

    getPartners = async () => api.get(`${PARTNERS_ENDPOINT}/`);

    getPartner = async partnerId => api.get(`${PARTNERS_ENDPOINT}/${partnerId}`);

    createPartner = async payload => api.post(`${PARTNERS_ENDPOINT}/`, payload);

    updatePartner = async (partnerId, payload) => api.put(`${PARTNERS_ENDPOINT}/${partnerId}`, payload);

    deletePartner = async partnerId => api.delete(`${PARTNERS_ENDPOINT}/${partnerId}`);

    getImportConfigFields = async () => api.get(`${IMPORT_CONFIG_FIELDS_ENDPOINT}/`);

    getImportConfigs = async () => api.get(`${IMPORT_CONFIGS_ENDPOINT}/`);

    getImportConfig = async configId => api.get(`${IMPORT_CONFIGS_ENDPOINT}/${configId}`);

    getExternalSftps = async () => api.get(`${EXTERNAL_SFTPS_ENDPOINT}/`);

    getExternalSftp = async externalSftpId => api.get(`${EXTERNAL_SFTPS_ENDPOINT}/${externalSftpId}`);

    createExternalSftp = async payload => api.post(`${EXTERNAL_SFTPS_ENDPOINT}/`, payload);

    createImportConfig = async payload => api.post(`${IMPORT_CONFIGS_ENDPOINT}/`, payload);

    updateImportConfig = async (importConfigId, payload) =>
        api.put(`${IMPORT_CONFIGS_ENDPOINT}/${importConfigId}`, payload);

    updateExternalSftp = async (externalSftpId, payload) =>
        api.put(`${EXTERNAL_SFTPS_ENDPOINT}/${externalSftpId}`, payload);

    createManualFileUpload = async payload => api.post(`${MANUAL_FILES_ENDPOINT}`, payload);

    deleteImportConfig = async importConfigId => api.delete(`${IMPORT_CONFIGS_ENDPOINT}/${importConfigId}`);

    deleteExternalSftp = async externalSftpId => api.delete(`${EXTERNAL_SFTPS_ENDPOINT}/${externalSftpId}`);

    uploadFile = async formData =>
        api.post(`${HJ_CDN_BASE_URL}/external/upload-tmp`, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
}

export default new ApiServices();
