import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const PlatformLayout = ({children}) => <div className='platform-layout'>{children}</div>;

PlatformLayout.propTypes = {
    children: PropTypes.node
};

export default PlatformLayout;
