import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Partner from './Partner';
import {requestPartners, deletePartner} from '../../../actions/partners';
import {getPartners} from '../../../selectors/partners';
import './index.scss';

class List extends PureComponent {
    componentDidMount = () => {
        this.props.initPartners();
    };

    renderPartnerList = () => {
        const {partnerList, removePartner} = this.props;

        return (
            <React.Fragment>
                {partnerList.map((partner, index) => (
                    <Partner
                        key={`partner_${partner.id}`}
                        onDelete={() => removePartner(partner.id)}
                        displaySeparator={index < partnerList.length - 1}
                        {...partner}
                    />
                ))}
            </React.Fragment>
        );
    };

    render = () => {
        return (
            <div className='partners-list'>
                <Row className='list-header'>
                    <Column sm={5}>
                        <Text fontWeight='bold'>Data Source</Text>
                    </Column>
                    <Column sm={6}>
                        <Text fontWeight='bold'>Data Types</Text>
                    </Column>
                </Row>
                {this.renderPartnerList()}
            </div>
        );
    };
}

List.propTypes = {
    initPartners: PropTypes.func.isRequired,
    removePartner: PropTypes.func.isRequired,
    partnerList: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = state => {
    return {
        partnerList: getPartners(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initPartners: () => dispatch(requestPartners()),
        removePartner: partnerId => dispatch(deletePartner(partnerId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
