import React from 'react';
import PropTypes from 'prop-types';
import Column from '@frontend/ui-kit/Components/Column';
import Heading from '@frontend/ui-kit/Components/Heading';
import Separator from '@frontend/ui-kit/Components/Separator';

const Section = ({children, header, displaySeparator, offset, sm}) => {
    return (
        <React.Fragment>
            {header && <Heading gradation={4}>{header}</Heading>}

            <Column smOffset={offset} sm={sm}>
                {children}
            </Column>

            {displaySeparator && <Separator />}
        </React.Fragment>
    );
};

Section.defaultProps = {
    displaySeparator: true,
    offset: 3,
    sm: 6
};

Section.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.string,
    displaySeparator: PropTypes.bool,
    offset: PropTypes.number,
    sm: PropTypes.number
};

export default Section;
