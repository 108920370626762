import Cookies from 'js-cookie';
import {AUTHORIZE, UNAUTHORIZE} from './actionTypes';
import apiServices from '../apiServices';
import {redirectTo} from './general';
import {getAccessToken, getRefreshToken} from '../selectors/authorization';
import {ROUTES} from '../constants';

const authorize = (accessToken, refreshToken, userName) => ({
    type: AUTHORIZE,
    accessToken,
    refreshToken,
    userName
});

const unauthorize = () => ({type: UNAUTHORIZE});

export const setAuthorizationData =
    (accessToken, refreshToken, userName = null) =>
    dispatch => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        if (userName) {
            localStorage.setItem('userName', userName);
        }

        Cookies.set('access_token', accessToken, {
            expires: 1,
            path: ROUTES.root,
            domain: window.location.hostname.split('.').slice(1).join('.')
        });

        dispatch(authorize(accessToken, refreshToken, userName));
    };

const removeAuthorizationData = () => dispatch => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userName');

    Cookies.remove('access_token', {path: ROUTES.root, domain: window.location.hostname.split('.').slice(1).join('.')});

    dispatch(unauthorize());
};

export const login = (username, password) => async () => {
    const {data: authData, isSuccess} = await apiServices.login({username, password});
    const {message} = authData;

    return {
        isSuccess,
        authData,
        submissionGlobalError: message
    };
};

export const signOut = () => async (dispatch, getState) => {
    const state = getState();
    const accessToken = getAccessToken(state);
    const refreshToken = getRefreshToken(state);

    try {
        await apiServices.signOut({accessToken, refreshToken});
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`signOut error: ${e}`);
    }

    dispatch(removeAuthorizationData());
    dispatch(redirectTo(ROUTES.login));
};

export const handleTokenRefresh = (isSuccess, tokenData) => dispatch => {
    const {access_token: accessToken, refresh_token: refreshToken} = tokenData;

    if (!isSuccess) {
        dispatch(removeAuthorizationData());
        return dispatch(redirectTo(ROUTES.login));
    }

    dispatch(setAuthorizationData(accessToken, refreshToken));
};
