import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Separator from '@frontend/ui-kit/Components/Separator';
import Link from '@frontend/ui-kit/Components/Link';
import Icon from '@frontend/ui-kit/Components/Icon';
import ConfirmDeletePopup from '../../shared/ConfirmDeletePopup';

class Partner extends PureComponent {
    state = {
        confirmDelete: false
    };

    handleDeleteConfirm = () => {
        this.props.onDelete();
        this.handleDeleteClose();
    };

    handleDeleteOpen = () => this.setState({confirmDelete: true});

    handleDeleteClose = () => this.setState({confirmDelete: false});

    render = () => {
        const {confirmDelete} = this.state;
        const {id, name, dataTypes, displaySeparator} = this.props;
        const dataTypesText = dataTypes && dataTypes.length ? dataTypes.join(', ') : '--';
        const confirmText = `Delete "${name}"?`; // eslint-disable-line jsx-quotes

        return (
            <div className='list-item'>
                <Row>
                    <Column sm={5}>
                        <Text>{name}</Text>
                    </Column>
                    <Column sm={6}>
                        <Text>{dataTypesText}</Text>
                    </Column>
                    <Column sm={1} className='list-item-actions'>
                        <Link href={`/partners/${id}/edit?id=${id}`}>
                            <Icon type='edit' />
                        </Link>
                        <span className='item-delete' onClick={this.handleDeleteOpen}>
                            <Icon type='trash' />
                        </span>
                    </Column>
                </Row>

                <ConfirmDeletePopup
                    isOpened={confirmDelete}
                    onClose={this.handleDeleteClose}
                    onDelete={this.handleDeleteConfirm}
                    text={confirmText}
                />

                {displaySeparator && <Separator />}
            </div>
        );
    };
}

Partner.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    dataTypes: PropTypes.arrayOf(PropTypes.string),
    displaySeparator: PropTypes.bool,
    onDelete: PropTypes.func
};

export default Partner;
