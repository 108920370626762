import React from 'react';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/react';
import Container from '@frontend/ui-kit/Components/Container';
import Router from './Router';
import Loader from './Components/shared/Loader';
import store from './store';

class App extends React.PureComponent {
    onBeforeCapture = scope => {
        scope.setLevel('fatal');
    };

    render = () => (
        <Sentry.ErrorBoundary beforeCapture={this.onBeforeCapture}>
            <Provider store={store}>
                <Container fluid>
                    <Loader />

                    <Router />
                </Container>
            </Provider>
        </Sentry.ErrorBoundary>
    );
}

export default App;
