import React from 'react';
import {useSelector} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {Route, Switch, Redirect} from 'react-router-dom';
import Pages from './Pages';
import withPlatformLayout from './HOC/withPlatformLayout';
import {getAuthorizationStatus} from './selectors/authorization';
import {ROUTES} from './constants';
import history from './history';

const RouteInnerPlatform = withPlatformLayout(Route);

const Router = () => {
    const isAuthorized = useSelector(getAuthorizationStatus);
    const RootComponent = () => <Redirect to={isAuthorized ? ROUTES.partners : ROUTES.login} />;

    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route exact path={ROUTES.root} component={RootComponent} />

                {!isAuthorized && <Route path={ROUTES.login} component={Pages.Login} />}

                {isAuthorized && (
                    <RouteInnerPlatform exact path={ROUTES.partnersCreate} component={Pages.PartnersCreate} />
                )}
                {isAuthorized && <RouteInnerPlatform exact path={ROUTES.partnersEdit} component={Pages.PartnersEdit} />}
                {isAuthorized && <RouteInnerPlatform exact path={ROUTES.partners} component={Pages.PartnersList} />}

                {isAuthorized && (
                    <RouteInnerPlatform exact path={ROUTES.importConfigsCreate} component={Pages.ImportConfigsCreate} />
                )}
                {isAuthorized && (
                    <RouteInnerPlatform exact path={ROUTES.importConfigsEdit} component={Pages.ImportConfigsEdit} />
                )}
                {isAuthorized && (
                    <RouteInnerPlatform exact path={ROUTES.importConfigs} component={Pages.ImportConfigsList} />
                )}

                <Route path={ROUTES.pageNotFound} component={Pages.PageNotFound} />
                <Route path={`${ROUTES.serverError}/:errorCode`} component={Pages.ServerError} />

                <Route path='*' component={Pages.PageNotFound} />
            </Switch>
        </ConnectedRouter>
    );
};

export default Router;
