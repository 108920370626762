import React from 'react';
import Link from '@frontend/ui-kit/Components/Link';
import PageHeading from '../../../Components/shared/PageHeading';
import PageLayout from '../../../Components/shared/PageLayout';
import ImportConfigsList from '../../../Components/ImportConfigs/List';

const List = () => {
    const button = (
        <Link isButton href='/import-configs/create'>
            + Create Import Config
        </Link>
    );

    return (
        <React.Fragment>
            <PageHeading button={button}>Import Config</PageHeading>

            <PageLayout>
                <ImportConfigsList />
            </PageLayout>
        </React.Fragment>
    );
};

export default List;
