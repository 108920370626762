import axios from 'axios';
import {
    IMPORT_CONFIG_DELETED,
    IMPORT_CONFIG_FIELDS_RECEIVED,
    IMPORT_CONFIGS_RECEIVED,
    EXTERNAL_SFTP_DELETED,
    SET_IMPORT_CONFIG_SUCCESS_STATUS,
    SET_EXTERNAL_SFTP_SUCCESS_STATUS,
    SET_SHOW_MANUAL_FILE_UPLOAD
} from './actionTypes';
import apiServices from '../apiServices';
import {HTTP_CODE_UNPROCESSABLE} from '../constants';
import {isEmpty, equal, getItemKeyValue, scrollToComponent} from '../utils';

const importConfigFieldsReceived = (fieldsData, partnersData) => ({
    type: IMPORT_CONFIG_FIELDS_RECEIVED,
    fieldsData,
    partnersData
});

const importConfigsReceived = data => ({type: IMPORT_CONFIGS_RECEIVED, ...data});
const importConfigUpdated = isSuccess => ({type: SET_IMPORT_CONFIG_SUCCESS_STATUS, isSuccess});
const importConfigDeleted = importConfigId => ({type: IMPORT_CONFIG_DELETED, importConfigId});

const externalSftpUpdated = isSuccess => ({type: SET_EXTERNAL_SFTP_SUCCESS_STATUS, isSuccess});
const externalSftpDeleted = externalSftpId => ({type: EXTERNAL_SFTP_DELETED, externalSftpId});

export const showManualFileUploadForConfigIdUpdated = configId => ({type: SET_SHOW_MANUAL_FILE_UPLOAD, configId});

export const requestImportConfigs = () => async dispatch => {
    const {data, isSuccess} = await apiServices.getImportConfigs();

    if (isSuccess) {
        dispatch(importConfigsReceived(data));
        dispatch(importConfigUpdated(false));
    }
};

export const deleteImportConfig = importConfigId => async dispatch => {
    const {isSuccess} = await apiServices.deleteImportConfig(importConfigId);

    if (isSuccess) {
        dispatch(importConfigDeleted(importConfigId));
    }
};

export const deleteExternalSftp = externalSftpId => async dispatch => {
    try {
        const {isSuccess} = await apiServices.deleteExternalSftp(externalSftpId);

        if (isSuccess) {
            dispatch(externalSftpDeleted(externalSftpId));
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
    }
};

export const initImportConfigForm = (importConfigId) => async dispatch => {
    const [
        {data: fieldsData, isSuccess: getImportConfigFieldsSuccess},
        {data: partnersData, isSuccess: getPartnersSuccess}
    ] = await axios.all([apiServices.getImportConfigFields(), apiServices.getPartners()]);

    const {data: formData, isSuccess: getImportConfigSuccess} = importConfigId
        ? await apiServices.getImportConfig(importConfigId)
        : {data: {}, isSuccess: true};

    const externsalSftpId = formData.external_sftp_id ? formData.external_sftp_id.toString() : null;
    const {data: externalSftpData, isSuccess: getExternalSftpSuccess} = externsalSftpId
        ? await apiServices.getExternalSftp(externsalSftpId)
        : {isSuccess: true};

    formData.external_sftp = externalSftpData;

    if (!getImportConfigFieldsSuccess) {
        const {message: fieldsErrorMessage} = fieldsData;
        return {submissionGlobalError: fieldsErrorMessage};
    }
    if (!getPartnersSuccess) {
        const {message: partnersErrorMessage} = partnersData;
        return {submissionGlobalError: partnersErrorMessage};
    }
    if (!getExternalSftpSuccess) {
        const {message: ExternalSftpErrorMessage} = externalSftpData;
        return {submissionGlobalError: ExternalSftpErrorMessage};
    }
    if (!getImportConfigSuccess) {
        const {message: importConfigErrorMessage} = formData;
        return {submissionGlobalError: importConfigErrorMessage};
    }

    dispatch(importConfigFieldsReceived(fieldsData, partnersData));

    const {data_mappings: dataMappings, join_key: joinKey} = formData;
    const hasMultipleRowsPerRecord = joinKey ? 'multiple' : 'single';

    // Groups event data mappings by healthjoy_field
    const groupEventDataMappings = dataMappings => {
        const eventData = dataMappings.reduce((acc, currentMapping) => {
            const healthjoyField = currentMapping.healthjoy_field;

            return {...acc, [healthjoyField]: [...(acc[healthjoyField] || []), currentMapping]};
        }, {});

        return eventData;
    };

    const getFormattedEventDataMappings = dataMappings => {
        const groupedMappings = groupEventDataMappings(dataMappings);

        const formattedDataMappings = Object.keys(groupedMappings).map(healthjoyField => {
            const pipeDelimitedEventDataField = groupedMappings[healthjoyField]
                .map(getItemKeyValue('data_field'))
                .join('|');
            const formattedDataMapping = {
                ...groupedMappings[healthjoyField][0],
                data_field: pipeDelimitedEventDataField
            };

            return formattedDataMapping;
        });

        return formattedDataMappings;
    };

    const dataMapping = !isEmpty(dataMappings) ? getFormattedEventDataMappings(dataMappings) : [{}];
    const formattedDataMapping = dataMapping.reduce((acc, obj) => {
        const {healthjoy_field: field, data_field: value} = obj;
        const healthjoyField = field;
        const dataField = value;
        acc[healthjoyField] = dataField;

        return acc;
    }, {});

    const data = {
        ...formData,
        ...formattedDataMapping,
        has_multiple_rows_per_record: hasMultipleRowsPerRecord,
        partnerOptions: partnersData
    };

    return {data};
};

export const saveImportConfigForm = (importConfigId, payload) => async dispatch => {
    const {data, isSuccess, status} = importConfigId
        ? await apiServices.updateImportConfig(importConfigId, payload)
        : await apiServices.createImportConfig(payload);

    if (!isSuccess) {
        const {
            message: {_schema: formError}
        } = data;
        const errorMessage = equal(status, HTTP_CODE_UNPROCESSABLE) ? formError : {_error: formError};
        return {submissionGlobalError: errorMessage};
    }

    dispatch(importConfigUpdated(isSuccess));

    const alert = document.getElementsByClassName('import-config-alert')[0];
    scrollToComponent(alert, 1000);

    return {data, isSuccess, isEdit: !!importConfigId};
};

export const saveExternalSftp = (externalSftpId, payload) => async dispatch => {
    const {data, isSuccess, status} = externalSftpId
        ? await apiServices.updateExternalSftp(externalSftpId, payload)
        : await apiServices.createExternalSftp(payload);

    if (!isSuccess) {
        const {message} = data;
        const errorMessage = equal(status, HTTP_CODE_UNPROCESSABLE) ? `External SFTP: ${message}` : {_error: message};
        return {submissionGlobalError: errorMessage};
    }

    dispatch(externalSftpUpdated(isSuccess));

    return {data, isSuccess, isEdit: !!externalSftpId};
};

export const saveFileUploadForm = payload => async dispatch => {
    const {data, isSuccess, status} = await apiServices.createManualFileUpload(payload);

    if (!isSuccess) {
        const {message} = data;
        const errorMessage = equal(status, HTTP_CODE_UNPROCESSABLE) ? message : {_error: message};
        return {submissionGlobalError: errorMessage};
    }

    dispatch(showManualFileUploadForConfigIdUpdated(null));
};

export const uploadManualFile = file => async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);

    const {data, isSuccess} = await apiServices.uploadFile(formData);
    const {data: fileData, reason: errorMessage} = data;

    return {url: fileData?.url, errorMessage, isSuccess};
};
